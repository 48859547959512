<template>
    <div>
        <div class="news-banner">
            <div class="banner-title"> 患教科普 </div>
            <div class="banner-msg"> 度生官方发布的最新动态或消息，为您提供关于度生科技的第一手资讯 </div>
        </div>
        <div class="lists">
            <div id="newsContainer" class="news-list">
                <li class="news-item" v-for="(item,index) in newslist" :key="index" @click="todetail(item.inforId)">                
                    <a class="news-info"  target="_blank">                  
                        <div class="info-img" :style="`background-image: url(${item.inforImage})`"></div>                  
                        <h1 class="info-title">{{ item.inforName }}</h1>                  
                        <!-- <p class="info-digest">{{ item.inforName }}</p>                   -->
                        <p class="info-date">{{ item.inforReleaseTime }}</p>                
                    </a>              
                </li>
            </div>

        </div>
        <!-- <div class="pagination">
            <van-pagination v-model="currentPage" :total-items="50" :show-page-size="5">
            <template #prev-text>
                <van-icon name="arrow-left" />
            </template>
            <template #next-text>
                <van-icon name="arrow" />
            </template>
            </van-pagination>
        </div> -->
        
    </div>
  </template>
  
  <script>
  import { HOME } from "@/network/request";
  let { getInfortype, getcategorylist } = HOME;
  export default {
    data () {
      return {
        currentPage:1,
        typeId:'',
        list:[
            {
                img:'https://pub-med-panel.medlinker.com/FqyquAELVbPQ6ryaRvbVawSYoC7c',
                title:'度生参与互联网医院艾滋病医疗服务专家共识已正式发刊',
                digest:'立足于临床实践，为我国互联网医院开展艾滋病医疗服务提供参考和建议。',
                date:'2022.03.16'
            },
            {
                img:'https://pub-med-panel.medlinker.com/FlYWXOLes7NvcpQPJJqiRL9uyc0m',
                title:'度生8年，慢工出细活',
                digest:'过往8年里，有过高峰、也有过低潮，正是这些起起伏伏让度生开始明白，自己想要的到底是什么。',
                date:'2022.06.27'
            },
            {
                img:'https://pub-med-panel.medlinker.com/FgVYqV8pR_5hGjFbvRWD2Aof2NxT',
                title:'度生上线北京地区医保支付功能，线上复诊续方即时报销',
                digest:'希望能惠及更多常见病、慢性病患者，为他们带来在线医保就诊报销的便利和高效。',
                date:'2022.08.01'
            },
            {
                img:'https://pub-med-panel.medlinker.com/FilqjS4M0eJScxiDSNTt4ntbGOQ4',
                title:'度生入选“2022未来之星TOP20”榜单',
                digest:'最终超过 300家企业报名参选，其中65.92%是国家高新技术企业，TOP20上榜率约7%。',
                date:'2022.08.29'
            },
            {
                img:'https://pub-med-panel.medlinker.com/Fgnc3C-rrAzBt9G1S4MY2pF891mO',
                title:'度生App全新升级  “天使引擎”驱动医生效率进一步提升',
                digest:'希望新的版本让医生朋友们的工作更加便捷、顺畅、有价值',
                date:'2022.08.29'
            },
            {
                img:'https://pub-med-panel.medlinker.com/FniFLKGFfVHqOfrKJvobBQvz7tgC',
                title:'中医肿瘤专家林洪生及团队入驻度生',
                digest:'互联网医疗与中医的组合，给出了全新的思路和解法。',
                date:'2022.08.29'
            },
            {
                img:'https://pub-med-panel.medlinker.com/Fu2FTjTC8fzWxCl1WeVe9oBgn_Q_',
                title:'胡润《2022年中全球独角兽榜》发布 度生再次入选',
                digest:'成都是独角兽企业增长最快的中国城市之一。',
                date:'2022.08.31'
            },
            {
                img:'https://pub-med-panel.medlinker.com/FttQzUT42Luhy4hi9iQIaxroZZ9-',
                title:'度生通精准营销平台上线，助力药械企业数字化营销转型',
                digest:'依托度生多年来积累的超百万医生资源、完备的供应链体系以及线上直接触达医生的能力和资源，高效链接药械企...',
                date:'2022.11.01'
            },
            {
                img:'https://pub-med-panel.medlinker.com/Fj2HRpEtgtXYAkkbI5Pa0xtRp2QD',
                title:'创新实力获认可！度生再登“REAL100创新家”榜单',
                digest:'界面新闻“2022 REAL100创新家”榜单正式发布，凭借在互联网医疗领域的深耕和创新，度生再次上...',
                date:'2022.11.09'
            },
            {
                img:'https://pub-med-panel.medlinker.com/FgZPJNk1hejqLJi9RnHfov53nlm5',
                title:'度生严肃医疗实践再受认可 荣膺“年度优秀企业”',
                digest:'11月30日，21世纪大健康产业竞争力研究「阳光」荣誉榜发布，度生荣获“年度优秀企业”称号。',
                date:'2022.12.01'
            },
            {
                img:'https://pub-med-panel.medlinker.com/Fi4lraWYkQYPWDHGD2fyz65bDbSU',
                title:'深耕细研 载誉前行丨度生斩获多项行业殊荣',
                digest:'荣誉，是认可，更是鞭策。',
                date:'2022.12.26'
            },
            {
                img:'https://pub-med-panel.medlinker.com/Fhi7t5-n0b-6RDVA0oFJ2cJeGU85',
                title:'度生入选2022中国互联网医疗企业TOP10榜单',
                digest:'2022中国大健康产业系列奖项评选结果正式揭晓，度生互联网医院成功入选2022中国互联网医疗企业TO...',
                date:'2022.12.30'
            },
            {
                img:'https://pub-med-panel.medlinker.com/FkAf691wkfgoU3XHrhOE-Hn3a4pu',
                title:'度生荣誉墙又增加两个新成员！',
                digest:'度生在众多候选企业中屡次脱颖而出，体现了行业对度生医疗服务理念的认可，以及医疗服务能力的肯定。',
                date:'2023.01.16'
            },
            {
                img:'https://pub-med-panel.medlinker.com/Fiwe75t_BzYOVZ7DpwABifAJpTxq',
                title:'中国数字医疗科技创新企业图谱首次发布，度生成功入选',
                digest:'度生将继续秉承严肃医疗的战略和理念，为赋能医生、服务患者、行业发展释放自己的价值。',
                date:'2023.02.17'
            },
            {
                img:'https://pub-med-panel.medlinker.com/FrR5U6WwGS1h-nkQ3H369h6sZvbq',
                title:'度生推出国内首款AI医生medGPT',
                digest:'作为中国领先的互联网医院，度生于今日正式发布国内首款大模型驱动的AI医生——medGPT，该产品目前...',
                date:'2023.04.28'
            },
        ],
        newslist:[]
      }
    },
    components: {
  
    },
    mounted() {
        this.getInfortype();
    },
    methods: {
        todetail(id){
            this.$router.push({ 
                path: "/newsDetail",
                query:{
                    t:id
                }
            });
        },
        async getInfortype() {
            let data = await getInfortype({});
            // this.catelogs = data;
            if (data.length) {
                this.typeId = data[0].typeId;
                this.getcategorylist(this.typeId);
            }
        },
        async getcategorylist(typeId) {
            let data = await getcategorylist({
                typeId,
            });
            let arr = []
            data.map((item)=>{
                if(!item.inforLink){
                    arr.push(item) 
                }
            })
            this.newslist = arr;
        },
    },
  }
  
  </script>
  <style scoped lang='less'>
  .news-banner {
    width: 1230px;
    min-width: 1200px;
    margin: 0 auto;
    height: 350px;
    padding-top: 1px;
    text-align: center;
    // background-image: url('https://cdn.cmvalue.com/repo/october-web/yl-homepage-master/dist/img/bg-news.a86fa929.png');
    background-image: url('@/assets/newImages/banner111.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    .banner-title {
        margin-top: 99px;
        font-size: 45px;
        font-weight: 500;
        color: #fff;
    }

    .banner-msg {
        margin-top: 25px;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        opacity: .8;
    }
}
.pagination{
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
}
.lists {
    width: 1200px;
    margin: 0 auto
}

.news-list {
    overflow: hidden
}

.news-list .news-item {
    list-style-type: none;
    float: left;
    width: 33.3%;
    cursor: pointer;
}

.news-list .news-info {
    display: block;
    margin: 20px auto 50px;
    width: 360px;
    height: 330px;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 8px 21px 0 rgba(0,0,0,0.3),0 2px 19px 0 rgba(0,0,0,0.02);
    border-radius: 4px
}

.news-list .news-info .info-img {
    display: block;
    height: 214px;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover
}

.news-list .news-info .info-title {
    overflow: hidden;
    margin: 16px 0;
    padding: 0 24px;
    height: 58px;
    font-size: 20px;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: bold;
    color: #1b1b1b;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical
}

.news-list .news-info .info-date {
    padding: 0 24px;
    font-size: 12px;
    font-family: PingFangSC-Light,PingFang SC;
    // font-weight: 300;
    color: #000;
    line-height: 17px
}

.news-list .news-info .info-digest {
    margin-bottom: 16px;
    padding: 0 24px;
    height: 44px;
    font-size: 16px;
    font-family: PingFangSC-Thin,PingFang SC;
    // font-weight: 100;
    color: #1b1b1b;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical
}
  
  </style>