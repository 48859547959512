import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Home/Home'
import Enter from '../views/Enter/Enter'
import GoodsList from '../views/GoodsList/GoodsList'
import Details from '../views/Details/Details'
import Search from '../views/Search/Search'
import Disease from '../views/Disease/Disease'
import Help from '../views/Help/Help'
import One from '../views/Message/one.vue'
import Two from '../views/Message/two.vue'
import Three from '../views/Message/three.vue'
import Four from '../views/Message/four.vue'
import Five from '../views/Message/five.vue'
import Pay from '../views/pay/index.vue'
import introduction from '../views/newEdition/introduction/index.vue'
import contact from '../views/newEdition/contact/index.vue'
import honor from '../views/newEdition/honor/index.vue'
import history from '../views/newEdition/history/index.vue'
import doctor from '../views/newEdition/product/doctor.vue'
import drugstore from '../views/newEdition/product/drugstore.vue'
import patient from '../views/newEdition/product/patient.vue'
import news from '../views/newEdition/news/index.vue'
import newsDetail from '../views/newEdition/news/detail.vue'
import text from '../views/newEdition/product/text.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/enter',
    name: 'enter',
    component: Enter
  },
  {
    path: '/goodslist',
    name: 'goodslist',
    component: GoodsList
  },
  {
    path: '/details',
    name: 'details',
    component: Details
  }, {
    path: '/search',
    name: 'search',
    component: Search
  },
  {
    path: '/disease',
    name: 'disease',
    component: Disease
  },
  {
    path: '/help',
    name: 'help',
    component: Help
  },
  {
    path: '/one',
    name: 'one',
    component: One
  },
  {
    path: '/two',
    name: 'two',
    component: Two
  },
  {
    path: '/three',
    name: 'three',
    component: Three
  },
  {
    path: '/four',
    name: 'four',
    component: Four
  },
  {
    path: '/five',
    name: 'five',
    component: Five
  },
  {
    path: '/pay',
    name: 'pay',
    component: Pay
  },
  {
    path: '/introduction',
    name: 'introduction',
    component: introduction
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact
  },
  {
    path: '/honor',
    name: 'honor',
    component: honor
  },
  {
    path: '/history',
    name: 'history',
    component: history
  },
  {
    path: '/doctor',
    name: 'doctor',
    component: doctor
  },
  {
    path: '/drugstore',
    name: 'drugstore',
    component: drugstore
  },
  {
    path: '/patient',
    name: 'patient',
    component: patient
  },
  {
    path: '/news',
    name: 'news',
    component: news
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: newsDetail
  },
  {
    path: '/text',
    name: 'text',
    component: text
  },

  
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }


})

export default router
