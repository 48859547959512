<!--  -->
<template>
  <div class="one">
    <Nav></Nav>
    <div class="content">
      <div class="title">如何远离肠癌？</div>
      <div class="introduce">
        <span>文章来源:佚名 </span>
        <span>2021-04-12 14:05:54</span>
      </div>
      <div class="line"></div>
      <div class="msg">
        <p>
          肠癌已经我国城市第二高发的癌症了，发病率还在不断快速上升，怎么做才能远离肠癌呢？
        </p>
        <p>
          国外成功的实践表明，如有效防控肠癌，及时筛查是最重要的手段，怎么做筛查呢？
        </p>
        <p>
          此外一些生活方式的改善也能帮助我们降低肠癌发病风险，起到远离肠癌的作用，
        </p>
        <p>1） 膳食纤维、全谷物、乳制品</p>
        <p>
          研究显示每日膳食纤维食用量每增加10g，肠癌发病风险降低9%，每日全谷物食用量每增加90g，肠癌发病风险降低17%，乳制品每日食用量每增加400g，肠癌发病风险降低13%。
        </p>
        <p>2） 合理体育锻炼</p>
        <p>
          研究显示，与不进行高强度体力活动人群相比，每周进行不小于半小时高强度体力活动可降低15%肠癌发病风险。
        </p>

        <div class="mask222"></div>
        <div class="more">余下内容为付费内容，需付费后继续阅读</div>
        <div class="buy" @click="toPay">立即支付</div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Nav from "@/components/nav/nav.vue";
import Bottom from "@/components/bottom/bottom.vue";
export default {
  components: { Nav, Bottom },

  Navdata() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    toPay() {
      this.$router.push({ name: "pay" });
    },
  },
};
</script>
<style lang='less' src="./index.less"  >
</style>