<template>
    <div>
      <section class="banner"></section>
      <section class="tools">
          <div class="containter">
              <div class="section-title">数百种患者慢病管理工具更好的服务患者</div>
              <div class="section-line"></div>
              <div class="features">
                  <div class="feature-item">
                      <img src="@/assets/newImages/tools-1.png" />
                      <p>AI客户服务</p>
                  </div>
                  <div class="feature-item">
                      <img src="@/assets/newImages/tools-2.png" />
                      <p>患者健康管理群</p>
                  </div>
                  <div class="feature-item">
                      <img src="@/assets/newImages/tools-3.png" />
                      <p>智能穿戴设备</p>
                  </div>
                  <div class="feature-item other">
                      <img src="@/assets/newImages/tools-4.png" />
                      <p>云药房 </p>
                  </div>
                  <div class="feature-item">
                      <img src="@/assets/newImages/tools-5.png" />
                      <p>用药指导 </p>
                  </div>
              </div>
          </div>
      </section>
      <section class="dieases">
          <div class="section-title">12大领域、专业、有效、规范、智慧的慢病管理标准<br/>提升患者信心、奠定行业标杆</div>
          <div class="section-line"></div>
          <div class="diease-row">
              <div class="diease-item" v-for="(item,index) in list" :key="index">
                  <div class="disease-inner">
                      <img :alt="item.name" :src="item.img" />
                      <div class="diease-name">{{ item.name }}</div>
                      <div v-for="item1 in item.text">
                        <p >{{ item1 }}</p>
                      </div>
                  </div>
                  <div class="disease-mask">
                      <div>
                          <div class="disease-qrcode-tips">扫码入组</div>
                          <div class="disease-qrcode-name">{{ item.name }}</div>
                      </div>
                  </div>
                  <div class="disease-qrcode">
                      <img 
                          :alt="item.name" 
                          :src="item.img"
                      />
                  </div>
              </div>
          </div>
          <div class="more">其它20余种慢病领域即将上线…</div>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    data () {
      return {
          list:[
            {
              img:require('@/assets/newImages/tangniaobing.png'),
              name:'糖尿病',
              text:['协助控制血糖、合并症','预防并发症的发生','提高生命质量']
            },
            {
              img:require('@/assets/newImages/xiaochuan.png'),
              name:'呼吸哮喘',
              text:['预防及减少哮喘急性发作','维持肺功能水平接近正常']
            },
            {
              img:require('@/assets/newImages/hiv.png'),
              name:'HIV',
              text:['提升生活幸福指数','服药依从性','疾病控制情况']
            },
            {
              img:require('@/assets/newImages/ganbing.png'),
              name:'肝病',
              text:['HBV病毒载量稳定或减少/消失']
            },
            {
              img:require('@/assets/newImages/guanxinbing.png'),
              name:'心血管',
              text:['医生医助心联心','科学控制高危因素','让治疗安心更放心']
            },
            {
              img:require('@/assets/newImages/duodonzhangai.png'),
              name:'儿科',
              text:['儿童健康管理','医生在线问诊','疾病科普文章']
            },
            {
              img:require('@/assets/newImages/shenbing.png'),
              name:'慢性肾病',
              text:['血肌酐尿蛋白，跟踪监测提醒','专业营养评估，定期营养随访','及时用药指导，专业科普患教']
            },
            {
              img:require('@/assets/newImages/zhongliu.png'),
              name:'肿瘤',
              text:['营养管理，助力恢复','降低不良反应和复发转移率','提高患者生活质量和生存率']
            },
            {
              img:require('@/assets/newImages/jingshenxinli.png'),
              name:'精神心理',
              text:['预防复发，情绪稳定']
            },
            {
              img:require('@/assets/newImages/quanke.png'),
              name:'全科',
              text:['私人家医，享受优质生活','专业能力，搭建家庭壁垒']
            },
            {
              img:require('@/assets/newImages/fuke.png'),
              name:'妇科',
              text:['延缓内异症发展进程','长期管理疼痛','提高生存质量']
            },
            {
              img:require('@/assets/newImages/fengshi.png'),
              name:'中医',
              text:['中医科学管理','标本兼治护健康','显著提高生活质量']
            },
          ]
      }
    },
    components: {
  
    },
    mounted() {
  
    },
    methods: {
  
    },
  }
  
  </script>
  <style scoped lang='less'>
@charset "utf-8";* {
    box-sizing: border-box
}

body {
    font-family: 'PingFangSC-Thin','Microsoft YaHei light'
}

@font-face {
    font-family: "mediconfont";
    src: url('//at.alicdn.com/t/font_bomhhb0ckjz41jor.eot?t=1490255243344');
    src: url('//at.alicdn.com/t/font_bomhhb0ckjz41jor.eot?t=1490255243344#iefix') format('embedded-opentype'),url('//at.alicdn.com/t/font_bomhhb0ckjz41jor.woff?t=1490255243344') format('woff'),url('//at.alicdn.com/t/font_bomhhb0ckjz41jor.ttf?t=1490255243344') format('truetype'),url('//at.alicdn.com/t/font_bomhhb0ckjz41jor.svg?t=1490255243344#mediconfont') format('svg')
}

.mediconfont {
    font-family: "mediconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.icon-apple-gray:before {
    content: "\e601"
}

.icon-android-gray:before {
    content: "\e602"
}

.icon-zhaomingyi:before {
    content: "\e606"
}

.icon-toutiao:before {
    content: "\e608"
}

.icon-tianshigu:before {
    content: "\e609"
}

.icon-xiaozu:before {
    content: "\e60a"
}

.icon-gaokeyan:before {
    content: "\e60e"
}

.icon-yaochuzhen:before {
    content: "\e60f"
}

.icon-jiewenzhen:before {
    content: "\e610"
}

.icon-kaichufang:before {
    content: "\e611"
}

h1,h2,h3,h4,h5,h5 {
    font-weight: 100
}

.banner {
    min-width: 1200px;
    background-image: url("@/assets/newImages/patient-banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 730px
}

.banner .img {
    width: 1200px;
    margin: 0 auto;
    position: relative
}

.banner .img img {
    position: absolute;
    top: -13px;
    right: 0;
    width: 182px
}

.tools {
    min-width: 1200px;
    padding-top: 96px;
    background-image: url("@/assets/newImages/patient-tools.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 760px
}

.tools .containter {
    width: 1200px;
    margin: 0 auto
}

.tools .features {
    width: 100%;
    margin-top: 150px;
    width: 600px;
    overflow: hidden
}

.tools .features .feature-item {
    float: left;
    margin-right: 88px;
    margin-bottom: 48px
}

.tools .features .feature-item img {
    margin: 0 auto;
    display: block;
    width: 74px;
    height: 74px;
    margin-bottom: 6px
}

.tools .features .feature-item p {
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 400;
    color: #000;
    line-height: 25px
}

.tools .features .feature-item.other {
    margin-left: 91px;
    margin-right: 141px
}

.feature {
    background-image: url("@/assets/newImages/doctor-feature.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 888px
}

.section-title {
    text-align: center;
    font-size: 44px;
    font-family: PingFangSC-Light,PingFang SC;
    font-weight: 300;
    color: #202020;
    line-height: 62px;
    margin-bottom: 36px;
    letter-spacing: -1.59px
}

.section-line {
    width: 68px;
    height: 4px;
    background: #007aff;
    margin: 0 auto
}

.dieases {
    width: 1032px;
    margin: 96px auto
}

.dieases .section-line {
    margin-bottom: 56px
}

.dieases .more {
    text-align: center;
    margin-top: 44px;
    margin-bottom: 138px;
    font-size: 16px;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 400;
    color: #8c8c8c;
    line-height: 22px
}

.dieases .diease-row {
    overflow: hidden;
    margin-bottom: 16px
}

.dieases .diease-row .diease-item {
    float: left;
    width: 226px;
    height: 188px;
    background: #fff;
    box-shadow: 0 4px 20px 0 rgba(228,236,250,0.74);
    border-radius: 4px;
    overflow: hidden;
    margin: 16px;
    text-align: center;
    position: relative
}

.dieases .diease-row .diease-item img {
    display: block;
    margin: 17px auto 6px;
    width: 40px;
    height: 40px
}

.dieases .diease-row .diease-item .diease-name {
    font-size: 16px;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 400;
    color: #007aff;
    line-height: 22px;
    margin-bottom: 12px
}

.dieases .diease-row .diease-item p {
    max-width: 180px;
    margin: 0 auto;
    display: inline-block;
    font-size: 12px;
    font-family: PingFangSC-Light,PingFang SC;
    font-weight: 300;
    color: #1b1b1b;
    line-height: 17px;
    text-align: left;
    margin-bottom: 6px;
    padding-left: 14px;
    position: relative
}

.dieases .diease-row .diease-item p:before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 7px;
    height: 7px;
    top: 5px;
    left: 0;
    border-radius: 50%;
    background: #007aff
}

.dieases .diease-row .diease-item .disease-mask {
    top: 0;
    opacity: 0;
    height: 0;
    width: 226px;
    position: absolute;
    background-color: #fff;
    overflow: hidden;
    transform: scale(.92);
    -ms-transform: scale(.92);
    -webkit-transform: scale(.92);
    -o-transform: scale(.92);
    -moz-transform: scale(.92)
}

.dieases .diease-row .diease-item .disease-mask>div {
    width: 226px;
    height: 188px;
    background: linear-gradient(180deg, #007aff 0, rgba(0,122,255,0.47) 100%)
}

.dieases .diease-row .diease-item .disease-qrcode {
    opacity: 0;
    padding-top: 14px;
    text-align: center;
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;
    transform: scale(.9);
    -ms-transform: scale(.9);
    -webkit-transform: scale(.9);
    -o-transform: scale(.9);
    -moz-transform: scale(.9)
}

.dieases .diease-row .diease-item .disease-qrcode img {
    display: inline-block;
    width: 72px;
    height: 72px
}

.dieases .diease-row .diease-item .disease-qrcode-tips {
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Light,PingFang SC;
    font-weight: 300;
    color: #fff;
    line-height: 20px;
    padding-top: 118px;
    margin-bottom: 6px
}

.dieases .diease-row .diease-item .disease-qrcode-name {
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 400;
    color: #fff;
    line-height: 22px
}

.dieases .diease-row .diease-item:hover .disease-inner {
    opacity: 0;
    transform: scale(1.1);
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    -moz-transform: scale(1.1);
    transition: all .3s;
    -moz-transition: all .3s;
    -webkit-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s
}

.dieases .diease-row .diease-item:hover .disease-mask {
    height: 188px;
    opacity: 1;
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    -moz-transform: scale(1);
    transition: all .3s linear .2s;
    -moz-transition: all .3s linear .2s;
    -webkit-transition: all .3s linear .2s;
    -ms-transition: all .3s linear .2s;
    -o-transition: all .3s linear .2s
}

.dieases .diease-row .diease-item:hover .disease-qrcode {
    opacity: 1;
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    -moz-transform: scale(1);
    transition: all .25s linear .2s;
    -moz-transition: all .25s linear .2s;
    -webkit-transition: all .25s linear .2s;
    -ms-transition: all .25s linear .2s;
    -o-transition: all .25s linear .2s
}

  
  </style>