<template>
  <div id="app">
    <Nav></Nav>
    <router-link to="/"></router-link>
    <!-- <router-view v-if="isRouterAlive" /> -->


    <router-view />
    <Bottom></Bottom>
  </div>
</template>
<script>
import Nav from "./components/nav/nav.vue";
import Bottom from "./components/bottom/bottom.vue";
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  components: { Nav,Bottom },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
}

a {
  color: black;
}
</style>
