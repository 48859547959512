class URLS {

    static HOME = {
        getInfortype: '/tableinfor/tableInfor/getInfortype',
        getcategorylist: '/tableinfor/tableInfor/getcategorylist',
        queryInfoDetailById: '/tableinfor/tableInfor/queryInfoDetailById'
    }

    constructor() {

    }
}

const HOMEMODULE = URLS.HOME
export {
    HOMEMODULE,
}
