<template>
    <div>
        <section class="banner"></section>
        <section class="Al">
            <div class="containter">
                <div class="section-title">100+种基于AI及数据分析技术的患者管理工具</div>
                <div class="section-line"></div>
                <div class="features">
                    <div class="feature-item">
                        <img src="@/assets/newImages/icon-Al-1.png" />
                        <p>AI医助</p>
                    </div>
                    <div class="feature-item">
                        <img src="@/assets/newImages/icon-Al-2.png" />
                        <p>智能审方系统</p>
                    </div>
                    <div class="feature-item">
                        <img src="@/assets/newImages/icon-Al-3.png" />
                        <p>复诊用药提醒</p>
                    </div>
                    <div class="feature-item other">
                        <img src="@/assets/newImages/icon-Al-4.png" />
                        <p>结构化数据</p>
                    </div>
                    <div class="feature-item">
                        <img src="@/assets/newImages/icon-Al-5.png" />
                        <p>智能随诊数据库</p>
                    </div>
                </div>
                <div class="patient-manage">每位医生可管理<span>2000</span>患者</div>
            </div>
        </section>
        <section class="feature"></section>
    </div>
  </template>
  
  <script>
  export default {
    data () {
      return {
  
      }
    },
    components: {
  
    },
    mounted() {
  
    },
    methods: {
  
    },
  }
  
  </script>
  <style scoped lang='less'>

@charset "utf-8";* {
    box-sizing: border-box
}

body {
    font-family: 'PingFangSC-Thin','Microsoft YaHei light'
}

@font-face {
    font-family: "mediconfont";
    src: url('//at.alicdn.com/t/font_bomhhb0ckjz41jor.eot?t=1490255243344');
    src: url('//at.alicdn.com/t/font_bomhhb0ckjz41jor.eot?t=1490255243344#iefix') format('embedded-opentype'),url('//at.alicdn.com/t/font_bomhhb0ckjz41jor.woff?t=1490255243344') format('woff'),url('//at.alicdn.com/t/font_bomhhb0ckjz41jor.ttf?t=1490255243344') format('truetype'),url('//at.alicdn.com/t/font_bomhhb0ckjz41jor.svg?t=1490255243344#mediconfont') format('svg')
}

.mediconfont {
    font-family: "mediconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.icon-apple-gray:before {
    content: "\e601"
}

.icon-android-gray:before {
    content: "\e602"
}

.icon-zhaomingyi:before {
    content: "\e606"
}

.icon-toutiao:before {
    content: "\e608"
}

.icon-tianshigu:before {
    content: "\e609"
}

.icon-xiaozu:before {
    content: "\e60a"
}

.icon-gaokeyan:before {
    content: "\e60e"
}

.icon-yaochuzhen:before {
    content: "\e60f"
}

.icon-jiewenzhen:before {
    content: "\e610"
}

.icon-kaichufang:before {
    content: "\e611"
}

h1,h2,h3,h4,h5,h5 {
    font-weight: 100
}

.banner {
    min-width: 1200px;
    background-image: url("@/assets/newImages/doctor-banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 730px
}

.banner .img {
    width: 1200px;
    margin: 0 auto;
    position: relative
}


.Al {
    min-width: 1200px;
    padding-top: 96px;
    background-image: url("@/assets/newImages/doctor-al.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 1080px
}

.Al .containter {
    width: 1200px;
    margin: 0 auto
}

.Al .features {
    width: 100%;
    margin-top: 220px;
    width: 600px;
    overflow: hidden
}

.Al .features .feature-item {
    float: left;
    margin-right: 84px;
    margin-bottom: 14px
}

.Al .features .feature-item img {
    margin: 0 auto;
    display: block;
    width: 108px;
    height: 108px;
    margin-bottom: 9px
}

.Al .features .feature-item p {
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 400;
    color: #000;
    line-height: 25px
}

.Al .features .feature-item.other {
    margin-left: 78px;
    margin-right: 108px
}

.Al .patient-manage {
    width: 500px;
    margin-top: 56px;
    font-size: 20px;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 400;
    color: #000;
    line-height: 28px;
    text-align: center
}

.Al .patient-manage span {
    color: #007aff
}

.feature {
    min-width: 1200px;
    background-image: url("@/assets/newImages/doctor-feature.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 888px
}


.section-title {
    text-align: center;
    font-size: 44px;
    font-family: PingFangSC-Light,PingFang SC;
    font-weight: 300;
    color: #202020;
    line-height: 62px;
    margin-bottom: 36px;
    letter-spacing: -1.59px
}

.section-line {
    width: 68px;
    height: 4px;
    background: #007aff;
    margin: 0 auto
}

.sub-title {
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 400;
    color: #202020;
    line-height: 25px;
    margin-top: 56px;
    margin-bottom: 36px
}

.text1 {
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC-Light,PingFang SC;
    font-weight: 300;
    color: #202020;
    line-height: 22px
}

.text2 {
    font-size: 14px;
    text-align: center;
    font-family: PingFangSC-Ultralight,PingFang SC;
    font-weight: 200;
    color: #202020;
    line-height: 20px;
    margin-top: 6px;
    margin-bottom: 32px
}

.photos {
    width: 1200px;
    margin: 62px auto;
    overflow: hidden
}

.photos img {
    width: 488px;
    display: block;
    margin-bottom: 17px
}

.photos .left {
    float: left
}

.photos .right {
    float: right
}

  </style>