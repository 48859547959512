<!--  -->
<template>
  <div class="one">
    <Nav></Nav>
    <div class="content">
      <div class="title">
        带女朋友吃龙虾害她拉肚子了，怎么办，女朋友还在厕所等结果...
      </div>
      <div class="introduce">
        <span>文章来源:佚名 </span>
        <span>2021-04-12 14:05:54</span>
      </div>
      <div class="line"></div>
      <div class="msg">
        <p>
          他和女朋友刚吃了夜宵回来，结果女朋友就拉肚子了，还抱怨说都是因为小龙虾不干净。
        </p>
        <p>
          其实女朋友晚上想吃的是烧烤，但是他想吃小龙虾，最后迁就自己才去吃的，结果现在还出了这种事，两个人的关系比女朋友现在拉的屎还臭，他就想问问，拉肚子真是小龙虾害的吗？
        </p>
        <p>为什么会拉肚子？</p>
        <p>腹泻基本上有两种类型：急性和慢性</p>
        <p>
          绝大多数人的腹泻是急性的。这种类型的腹泻也称为非炎性腹泻，通常是细菌或病毒引起的。没错，小龙虾不干净是有可能让女朋友拉肚子的
        </p>
        <p>
          好在这种情况几天就会好。它的症状包括：大便水样，频繁，伴有胃痉挛和恶心，屁也可能变多。
        </p>
        <p>既然错误已经造成，怎么才能从女朋友那里再赚回一点分呢？</p>
        <p>1.多喝水。</p>
        <p>
          腹泻的人可能会流失大量液体，同时也会流失电解质，矿物质（例如钠和钾），这些矿物质对人体的运转至关重要
        </p>

        <div class="mask444"></div>
        <div class="more">余下内容为付费内容，需付费后继续阅读</div>
        <div class="buy" @click="toPay">立即支付</div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Nav from "@/components/nav/nav.vue";
import Bottom from "@/components/bottom/bottom.vue";
export default {
  components: { Nav, Bottom },

  Navdata() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    toPay() {
      this.$router.push({ name: "pay" });
    },
  },
};
</script>
<style lang='less' src="./index.less"  >
</style>