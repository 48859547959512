<template>
  <div class="container">
    <div class="block1">
      <!-- <Nav></Nav> -->
      <NewHome></NewHome>
      <!-- <div class="health">
        <div class="health_cen">
          <Classify></Classify>
          <span class="health_1">度生健康</span>
          <span class="health_2">国内领先的专病专保科技平台</span>
        </div>
      </div> -->
      <!-- <div class="describe">
        <div class="describe_cen">
          <div class="describe_1">
            度生科技面向全国建立完整的专病专保服务网络
          </div>
          <div class="describe_2">
            &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp通过自建和共建互联网医院构建线上线下结合数字医疗服务体系，打造以数字支付为核心的专病专保服务平台，通过链接患者、医生、药房、保险公司和药企，为患者提供更有质量的医疗服务，助力患者解决费用负担和可及性问题，提升患者院前院后的疾病管理和健康管理水平。
          </div>
        </div>
      </div> -->
      <!-- <GoodsList id="GoodsList"></GoodsList> -->
      <!-- <div class="serve" id="serve">
        <div class="serve_cen">
          <div class="serve_1">产品服务</div>
          <div class="serve_2">
            <div class="serve_3 serve_3_2" @click="toGoodsList">
              <img
                src="../../assets/images/5.png"
                alt=""
                @mouseover="over"
                @mouseleave="leave"
                ref="serve_2"
              />
              <div class="serve_3_text" ref="serve_3_text_2">线上商城</div>
            </div>
            <div class="serve_3 serve_3_3" @click="toDisease">
              <img
                src="../../assets/images/6.png"
                alt=""
                @mouseover="over"
                @mouseleave="leave"
                ref="serve_3"
              />
              <div class="serve_3_text" ref="serve_3_text_3">专病专保</div>
            </div>
            <div class="serve_3 serve_3_4" @click="toHelp">
              <img
                src="../../assets/images/7.png"
                alt=""
                @mouseover="over"
                @mouseleave="leave"
                ref="serve_4"
              />
              <div class="serve_3_text" ref="serve_3_text_4">惠民保</div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="advantage">
        <div class="advantage_cen">
          <div class="advantage_1">公司优势</div>
          <div class="advantage_2">
            <div class="advantage_3 advantage_3_1">
              <img src="../../assets/images/9.png" alt="" />
              <div class="advantage_4">覆盖全国的服务网络</div>
            </div>
            <div class="advantage_3 advantage_3_2">
              <img src="../../assets/images/10.png" alt="" />
              <div class="advantage_4">数字化技术链接能力</div>
            </div>
            <div class="advantage_3 advantage_3_3">
              <img src="../../assets/images/11.png" alt="" />
              <div class="advantage_4">
                线上线下结合的产品化能力和连续健康管理服务
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="knowledge" id="knowledge">
        <div class="knowledge_cen">
          <div class="knowledge_2">
            健康知识
            <div class="knowledge_1">付费</div>
          </div>
          <div class="knowledge_3">
            <div class="knowledge_4" @click="toOne">
              <div class="knowledge_6">幽幽管取样前后注意事项</div>
            </div>
            <div class="knowledge_5">
              <div class="knowledge_7">
                <div class="hot">热读Top</div>
                <div class="more" @click="qwe">查看更多</div>
              </div>
              <div class="knowledge_8">
                <van-collapse v-model="activeName" accordion>
                  <van-collapse-item :name="index" v-for="(item, index) in listHost" :key="index">
                      <template #title>
                        <div class="title" :style="{ '--num':`'${index + 1}''` }">{{ item.title }}</div>
                      </template>
                      <template #right-icon><div class="date">2022-08-01</div></template>
                      <div @click="toOne">
                        {{ item.desc }}
                      </div>
                  </van-collapse-item>

                </van-collapse>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="contact" id="contact">
        <div class="contact_cen">
          <div class="contact_1">联系我们</div>
          <div class="contact_2">
            <div class="contact_2_1">上海 Shanghai</div>
            <div class="contact_2_2">上海市徐汇区钦州路100号2号楼</div>
          </div>
          <div class="contact_3">
            <div
              class="contact_4"
              ref="fuwu"
              @mouseover="conover('fuwu')"
              @mouseleave="conleave('fuwu')"
            >
              <div class="contact_4_1" ref="contact_4_1">服务热线</div>
              <div class="contact_4_2" ref="contact_4_2">021-61169118</div>
            </div>
            <div
              class="contact_4"
              ref="shichang"
              @mouseover="conover('shichang')"
              @mouseleave="conleave('shichang')"
            >
              <div class="contact_4_1" ref="contact_4_3">市场推广</div>
              <div class="contact_4_2" ref="contact_4_4">
                marketing@dushengtech.com
              </div>
            </div>
            <div
              class="contact_4"
              ref="join"
              @mouseover="conover('join')"
              @mouseleave="conleave('join')"
            >
              <div class="contact_4_1" ref="contact_4_5">加入我们</div>
              <div class="contact_4_2" ref="contact_4_6">
                hr@dushengtech.com
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="login" v-if="loginshow">
        <van-popup
          v-model="loginshow"
          round
          closeable
          close-icon="close"
          :style="{ height: '426px', width: '821px' }"
          class="show"
        >
          <div class="showBox">
            <van-form validate-first @failed="onFailed">
              <div class="login_text">
                <h2>您好！请登录</h2>
              </div>
              <van-field
                v-model="username"
                name="用户名"
                label="用户名/手机号"
                placeholder="用户名"
                :rules="[{ required: true, message: '请填写用户名' }]"
              />
              <van-field
                v-model="password"
                type="password"
                name="密码"
                label="密码"
                placeholder="密码"
                :rules="[{ required: true, message: '请填写密码' }]"
              />
              <van-checkbox-group
                v-model="checkboxGroup"
                direction="horizontal"
                class="password_text"
              >
                <van-checkbox name="1" shape="square">记住密码</van-checkbox>
                <div class="misspas">
                  <a href="javascript:void(0)">忘记密码？</a>
                </div>
              </van-checkbox-group>
              <div style="margin: 16px">
                <van-button round block type="info" native-type="submit"
                  >提交</van-button
                >
              </div>
              <div>
                还没有账号？<a href="javascript:void(0)" @click="register"
                  >立即注册</a
                >
              </div>
            </van-form>
          </div>
        </van-popup>
      </div>
      <div class="register" v-if="registershow">
        <van-popup
          v-model="registershow"
          round
          closeable
          close-icon="close"
          :style="{ height: '600px', width: '42%' }"
          class="show"
        >
          <div class="showBox">
            <van-form validate-first @failed="onFailed">
              <div class="login_text">
                <h2>您好！请注册</h2>
              </div>
              <van-field
                v-model="value1"
                label="手机号"
                name="pattern"
                placeholder="请输入手机号"
                :rules="[{ pattern, message: '请输入正确内容' }]"
              />
              <van-field
                v-model="sms"
                center
                clearable
                label="短信验证码"
                placeholder="请输入短信验证码"
              >
                <van-button id="noo" slot="button" size="small" type="primary"
                  >发送验证码</van-button
                >
              </van-field>
              <van-field
                v-model="password1"
                type="password"
                name="密码"
                label="密码"
                placeholder="密码"
                :rules="[{ required: true, message: '请输入密码' }]"
              />
              <van-field
                v-model="password2"
                type="password"
                name="确认密码"
                label="确认密码"
                placeholder="请输入确认密码"
                :rules="[{ required: true, message: '请输入确认密码' }]"
              />
              <van-field
                v-model="name"
                name="真实姓名"
                label="真实姓名"
                placeholder="请输入真实姓名"
                :rules="[
                  {
                    pattern: namepattern,
                    required: true,
                    message: '请输入正确的真实姓名',
                  },
                ]"
              />
              <van-field
                v-model="idnumber"
                name="身份证号"
                label="身份证号"
                placeholder="请输入身份证号"
                :rules="[
                  {
                    pattern: idpattern,
                    required: true,
                    message: '请输入正确的身份证号',
                  },
                ]"
              />
              <van-checkbox-group
                v-model="checkboxGroup"
                direction="horizontal"
                class="password_text"
              >
                <div style="margin-top: 16px">
                  <van-checkbox name="2" shape="square"
                    >我已认真阅读并同意签署<a href="javascript:void(0)"
                      >《平台服务协议》</a
                    >
                  </van-checkbox>
                </div>
              </van-checkbox-group>
              <div style="margin: 16px">
                <van-button round block type="info" native-type="submit"
                  >立即注册</van-button
                >
              </div>
              <div>
                已有账号？<a href="javascript:void(0)" @click="Login"
                  >立即登录</a
                >
              </div>
            </van-form>
          </div>
        </van-popup>
      </div>

      <!-- <bottom></bottom> -->
    </div>
  </div>
</template>

<script>
// import Bottom from "../../components/bottom/bottom.vue";
// import Nav from "../../components/nav/nav.vue";
import GoodsList from "../GoodsList/GoodsList.vue";
import Classify from "./classify.vue";
import NewHome from "./newHome.vue";
export default {
  data() {
    return {
      activeName: "",
      time: "2022-08-01",
      loginshow: false,
      registershow: false,
      value1: "",
      username: "",
      password: "",
      password1: "",
      password2: "",
      sms: "",
      name: "",
      idnumber: "",
      checkboxGroup: [],
      pattern:
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      namepattern: /^[\u2E80-\u9FFF]+$/,
      idpattern:
        /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
      listHost:[
        {
          title:'幽幽管取样前后注意事项',
          desc:'取新鲜粪便样本，成型或不成型粪便样本均可使用'
        },
        {
          title:'如何远离肠癌？',
          desc:'肠癌已经我国城市第二高发的癌症了，发病率还在不断快速上升，怎么做才能远离肠癌呢？'
        },
        {
          title:'吃冰伤胃还是吃辣伤胃？',
          desc:'辣椒本身并不伤胃，只不过其中的辣椒素会激活痛觉神经，让人感觉火辣辣的。但是这种痛并...'
        },
        {
          title:'带女朋友吃龙虾害她拉肚子了，怎么办，女朋友还在厕所等结果...',
          desc:'他和女朋友刚吃了夜宵回来，结果女朋友就拉肚子了，还抱怨说都是因为小龙虾不干净...'
        },
        {
          title:'哪些人是肠癌高危人群？',
          desc:'拥有肠癌危险因素的人群，尤其需要关注肠癌防控'
        }
      ]
    };
  },
  components: { GoodsList,Classify,NewHome },
  mounted() {},
  methods: {
    over(e) {
      if (e.path[1].className == "serve_3 serve_3_1") {
        const serve_1 = this.$refs.serve_1;
        const serve_3_text_1 = this.$refs.serve_3_text_1;

        serve_1.style.transition = "all .3s";
        serve_1.style.transform = `scale(1.1)`;
        serve_1.style.boxShadow = "-5px -5px 10px 5px rgba(0,0,0,0.1)";
        serve_3_text_1.style.color = `blue`;
      } else if (e.path[1].className == "serve_3 serve_3_2") {
        const serve_2 = this.$refs.serve_2;
        const serve_3_text_2 = this.$refs.serve_3_text_2;

        serve_2.style.transition = "all .3s";
        serve_2.style.transform = `scale(1.1)`;
        serve_2.style.boxShadow = "-5px -5px 10px 5px rgba(0,0,0,0.1)";
        serve_3_text_2.style.color = `blue`;
      } else if (e.path[1].className == "serve_3 serve_3_3") {
        const serve_3 = this.$refs.serve_3;
        const serve_3_text_3 = this.$refs.serve_3_text_3;

        serve_3.style.transition = "all .3s";
        serve_3.style.transform = `scale(1.1)`;
        serve_3.style.boxShadow = "-5px -5px 10px 5px rgba(0,0,0,0.1)";
        serve_3_text_3.style.color = `blue`;
      } else if (e.path[1].className == "serve_3 serve_3_4") {
        const serve_4 = this.$refs.serve_4;
        const serve_3_text_4 = this.$refs.serve_3_text_4;

        serve_4.style.transition = "all .3s";
        serve_4.style.transform = `scale(1.1)`;
        serve_4.style.boxShadow = "-5px -5px 10px 5px rgba(0,0,0,0.1)";
        serve_3_text_4.style.color = `blue`;
      }
    },
    leave(e) {
      if (e.path[1].className == "serve_3 serve_3_1") {
        const serve_1 = this.$refs.serve_1;
        const serve_3_text_1 = this.$refs.serve_3_text_1;

        serve_1.style.transition = "all .3s";

        serve_1.style.transform = `scale(1)`;
        serve_1.style.boxShadow = "none";
        serve_3_text_1.style.color = `black`;
      } else if (e.path[1].className == "serve_3 serve_3_2") {
        const serve_2 = this.$refs.serve_2;
        const serve_3_text_2 = this.$refs.serve_3_text_2;

        serve_2.style.transition = "all .3s";
        serve_2.style.transform = `scale(1)`;
        serve_2.style.boxShadow = "none";
        serve_3_text_2.style.color = `black`;
      } else if (e.path[1].className == "serve_3 serve_3_3") {
        const serve_3 = this.$refs.serve_3;
        const serve_3_text_3 = this.$refs.serve_3_text_3;

        serve_3.style.transition = "all .3s";
        serve_3.style.transform = `scale(1)`;
        serve_3.style.boxShadow = "none";
        serve_3_text_3.style.color = `black`;
      } else if (e.path[1].className == "serve_3 serve_3_4") {
        const serve_4 = this.$refs.serve_4;
        const serve_3_text_4 = this.$refs.serve_3_text_4;

        serve_4.style.transition = "all .3s";
        serve_4.style.transform = `scale(1)`;
        serve_4.style.boxShadow = "none";
        serve_3_text_4.style.color = `black`;
      }
    },

    conover(value) {
      const fuwu = this.$refs.fuwu;
      const contact_4_1 = this.$refs.contact_4_1;
      const contact_4_2 = this.$refs.contact_4_2;
      const shichang = this.$refs.shichang;
      const contact_4_3 = this.$refs.contact_4_3;
      const contact_4_4 = this.$refs.contact_4_4;
      const join = this.$refs.join;
      const contact_4_5 = this.$refs.contact_4_5;
      const contact_4_6 = this.$refs.contact_4_6;
      if (value == "fuwu") {
        fuwu.style.backgroundColor = "#2646FF";
        contact_4_2.style.color = "#FFFFFF";
        contact_4_1.style.color = "#FFFFFF";
      } else if (value == "shichang") {
        shichang.style.backgroundColor = "#2646FF";
        contact_4_3.style.color = "#FFFFFF";
        contact_4_4.style.color = "#FFFFFF";
      } else if (value == "join") {
        join.style.backgroundColor = "#2646FF";
        contact_4_5.style.color = "#FFFFFF";
        contact_4_6.style.color = "#FFFFFF";
      }
    },
    conleave(value) {
      const fuwu = this.$refs.fuwu;
      const contact_4_1 = this.$refs.contact_4_1;
      const contact_4_2 = this.$refs.contact_4_2;
      const shichang = this.$refs.shichang;
      const contact_4_3 = this.$refs.contact_4_3;
      const contact_4_4 = this.$refs.contact_4_4;
      const join = this.$refs.join;
      const contact_4_5 = this.$refs.contact_4_5;
      const contact_4_6 = this.$refs.contact_4_6;
      if (value == "fuwu") {
        fuwu.style.background = "#EDF0FF";
        contact_4_1.style.color = "#2646FF";
        contact_4_2.style.color = "#2646FF";
      } else if (value == "shichang") {
        shichang.style.background = "#EDF0FF";
        contact_4_3.style.color = "#2646FF";
        contact_4_4.style.color = "#2646FF";
      } else if (value == "join") {
        join.style.background = "#EDF0FF";
        contact_4_5.style.color = "#2646FF";
        contact_4_6.style.color = "#2646FF";
      }
    },
    toGoodsList() {
      this.$router.push({ path: "/goodslist" });
    },
    toDisease() {
      this.$router.push({ path: "/disease" });
    },
    toHelp() {
      this.$router.push({ path: "/help" });
    },
    toRegister() {
      this.registershow = true;
    },
    onFailed(errorInfo) {
      console.log("failed", errorInfo);
    },
    logshow() {
      this.loginshow = true;
    },
    register() {
      this.registershow = true;
      this.loginshow = false;
    },
    Login() {
      this.registershow = false;
      this.loginshow = true;
    },
    qwe() {
      this.loginshow = true;
    },
    toOne() {
      this.$router.push({ name: "one" });
    },
    togo(value) {
      if (value == 3) {
        this.$router.push({ name: "three" });
      } else if (value == 4) {
        this.$router.push({ name: "four" });
      } else if (value == 5) {
        this.$router.push({ name: "five" });
      }
    },
    toTwo() {
      this.$router.push({ name: "two" });
    },
  },
};
</script>
<style lang='less' src="./index.less"  >
</style>