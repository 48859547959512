<template>
    <div>
        <div class="news-detail">
            <div class="nav">
                <p>
                    <a @click="path('/')">首页</a> <span>&gt;</span> 
                    <a @click="path('/news')" >公司新闻</a> <span>&gt;</span> 
                    <span class="nav-title">{{ xjDetail.inforName }}</span>
                </p>
            </div>
            <div class="article">
                <div class="fl">
                    <div class="article-date">
                        <p class="date-first">{{ `${d2}/${d3}` }}</p>
                        <p class="years">{{ d1 }}</p>
                    </div>
                </div>
                <div class="article-main">
                    <h1 class="title">{{ xjDetail.inforName }}</h1>
                    <div class="info">
                        <span>作者: <span class="author">{{ xjDetail.createBy }}</span></span>
                        <span>/</span>
                        <span class="info-date">{{ xjDetail.createTime }}</span>
                    </div>
                    <div class="content" v-html="xjDetail.inforDetails"></div>
                </div>
            </div>
            <!-- <div class="nearby">
                <div class="prev">
                    上一条：
                    <span class="prev-title">
                        <a >医联推出国内首款AI医生medGPT</a>
                    </span>
                </div>
                <div class="next">
                    下一条：
                    <span class="next-title">
                        <a >医联荣誉墙又增加两个新成员！</a>
                    </span>
                </div>
            </div>
            <div class="relateNews">
                <div class="relaTitle">相关新闻</div>
                <div class="relateMain">
                    <div class="relateLeft">
                        <p></p><a >医联入选2022中国互联网医疗企业TOP10榜单</a><p></p>
                        <p></p><a >胡润《2022年中全球独角兽榜》发布 医联再次入选</a><p></p>
                        <p></p><a >医联严肃医疗实践再受认可 荣膺“年度优秀企业”</a><p></p>
                        <p></p><a >深耕细研 载誉前行丨医联斩获多项行业殊荣</a><p></p>
                    </div>
                    <div class="relateRight">
                        <p></p><a >医联通精准营销平台上线，助力药械企业数字化营销转型</a><p></p>
                        <p></p><a >医联推出国内首款AI医生medGPT</a><p></p>
                        <p></p><a >医联荣誉墙又增加两个新成员！</a><p></p>
                        <p></p><a >中国数字医疗科技创新企业图谱首次发布，医联成功入选</a><p></p>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
  </template>
  
  <script>
  import { HOME } from "@/network/request";
  let { queryInfoDetailById } = HOME;
  export default {
    data () {
      return {
        xjDetail:{},
        d1:'',
        d2:'',
        d3:'',
      }
    },
    watch: {
        $route: {
            handler: function(val, oldVal){
                this.queryInfoDetailById(val.query.t)
            },
            // 深度观察监听
            deep: true,
            immediate:true
        }
    },
    components: {
  
    },
    mounted() {
  
    },
    methods: {
        path(path){
            this.$router.push({ path: path });
        },
        queryInfoDetailById(infoId) {
            queryInfoDetailById({
                infoId,
            }).then((data) => {
                if (Object.keys(data).length) {
                    this.xjDetail = data;
                    this.d1 = (this.xjDetail.createTime).slice(0, 4)
                    this.d2 = (this.xjDetail.createTime).slice(5, 7)
                    this.d3 = (this.xjDetail.createTime).slice(8, 11)
                }
            });
        },
    },
  }
  
  </script>
  <style scoped lang='less'>

@charset "utf-8";* {
    box-sizing: border-box
}

body {
    font-family: 'PingFangSC-Thin','Microsoft YaHei light'
}

@font-face {
    font-family: "mediconfont";
    src: url('//at.alicdn.com/t/font_bomhhb0ckjz41jor.eot?t=1490255243344');
    src: url('//at.alicdn.com/t/font_bomhhb0ckjz41jor.eot?t=1490255243344#iefix') format('embedded-opentype'),url('//at.alicdn.com/t/font_bomhhb0ckjz41jor.woff?t=1490255243344') format('woff'),url('//at.alicdn.com/t/font_bomhhb0ckjz41jor.ttf?t=1490255243344') format('truetype'),url('//at.alicdn.com/t/font_bomhhb0ckjz41jor.svg?t=1490255243344#mediconfont') format('svg')
}

.mediconfont {
    font-family: "mediconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.icon-apple-gray:before {
    content: "\e601"
}

.icon-android-gray:before {
    content: "\e602"
}

.icon-zhaomingyi:before {
    content: "\e606"
}

.icon-toutiao:before {
    content: "\e608"
}

.icon-tianshigu:before {
    content: "\e609"
}

.icon-xiaozu:before {
    content: "\e60a"
}

.icon-gaokeyan:before {
    content: "\e60e"
}

.icon-yaochuzhen:before {
    content: "\e60f"
}

.icon-jiewenzhen:before {
    content: "\e610"
}

.icon-kaichufang:before {
    content: "\e611"
}

h1,h2,h3,h4,h5,h5 {
    font-weight: 100
}

.news-detail {
    width: 1200px;
    margin: auto;
    margin-top: 50px;
}

.news-detail .nav {
    font-family: PingFang-SC-Medium,PingFang-SC;
    font-size: 16px;
    font-weight: 500
}

.news-detail .nav a {
    color: #007AFF;
    cursor: pointer
}

.news-detail .article {
    margin-top: 60px
}

.news-detail .article .fl {
    float: left
}

.news-detail .article .fl:after {
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
    clear: both
}

.news-detail .article .article-date {
    display: block;
    width: 66px;
    padding: 12px 0;
    background-color: #007eff
}

.news-detail .article .article-date p {
    font-family: PingFang-SC-Medium,PingFang-SC;
    line-height: 1.2;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #fff
}

.news-detail .article .article-main {
    margin-left: 155px
}

.news-detail .article .article-main .title {
    margin: 0;
    font-size: 24px;
    font-weight: normal;
    font-family: PingFangSC-Regular,PingFang SC;
    color: #1D1D34
}

.news-detail .article .article-main .info {
    font-family: PingFang-SC-Medium,PingFang-SC;
    font-size: 12px;
    color: #808080
}

.news-detail .article .article-main .content {
    font-family: PingFangSC-Light,PingFang SC;
    margin-top: 15px;
    font-size: 14px;
    line-height: 2em;
    color: #454553;
    margin-bottom: 50px;
}

.news-detail .article .article-main .content img {
    max-width: 100%
}

.news-detail .nearby {
    margin: 40px 0;
    font-size: 16px;
    font-family: PingFang-SC-Medium,PingFang-SC;
    font-weight: 400
}

.news-detail .nearby:after {
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
    clear: both
}

.news-detail .nearby .prev {
    float: left;
    width: 48%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap
}

.news-detail .nearby .next {
    float: right;
    width: 48%;
    text-align: right;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap
}

.news-detail .nearby a {
    color: #007AFF
}

.news-detail .relateNews .relaTitle {
    font-size: 18px;
    font-family: PingFang-SC-Medium,PingFang-SC;
    font-weight: 400;
    padding-bottom: 10px;
    border-bottom: 1px solid #E5E7EB
}

.news-detail .relateNews .relateMain {
    padding: 20px
}

.news-detail .relateNews .relateMain:after {
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
    clear: both
}

.news-detail .relateNews .relateMain .relateLeft {
    float: left;
    width: 48%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap
}

.news-detail .relateNews .relateMain .relateRight {
    float: right;
    width: 48%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap
}

.news-detail .relateNews .relateMain a:hover {
    color: #007AFF
}

.news-detail .relateNews .relateMain p {
    margin-bottom: 10px
}

  </style>