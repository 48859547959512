<!--  -->
<template>
  <div class="one">
    <Nav></Nav>
    <div class="content">
      <div class="title">哪些人是肠癌高危人群？</div>
      <div class="introduce">
        <span>文章来源:佚名 </span>
        <span>2021-04-12 14:05:54</span>
      </div>
      <div class="line"></div>
      <div class="msg">
        <p>拥有肠癌危险因素的人群，尤其需要关注肠癌防控。</p>
        <p>哪些是肠癌危险因素？研究结果显示如下的因素都可以升高患肠癌风险：</p>
        <p>
          1）
          肠癌家族史，如果一个人的一级亲属有结直肠癌患者，那么他/她患肠癌风险比普通人高76%。什么是一级亲属呢？就是这个人的父母、子女、亲兄弟姐妹。
        </p>
        <p>
          2）
          炎症性肠病，如果一个人患了炎症性肠病，那么他/她的结直肠癌风险比普通人高的70%。
        </p>
        <p>
          3）
          食用红肉和加工肉，如果一个人每天食用红肉量每增加100g（2两），肠癌风险增加12%，加工肉类每天食用量每增加50g（1两），肠癌发病风险增加16%。什么是红肉呢？所有来自哺乳动物的肉（如牛羊猪兔马驴等的肉）都是红肉，加工肉类一般指腌制或熏制的红肉（如培根或火腿）制成的肉制品。
        </p>
        <p>4） 糖尿病，如果一个人患了糖尿病，他/她患肠癌风险比普通人高44%。</p>
        <p>5） 肥胖，腰围每增加10cm，患肠癌风险升高2%</p>
        <p>6） 吸烟，如果一个是吸烟，他/她患肠癌风险比不吸烟者高18%。</p>
        <!-- <p>4、艾叶泡脚</p>
        <p>
          想要春季去除身体的湿气，可以使用艾叶来泡脚，艾叶属于比较好的祛湿中药材，最好每天用艾叶泡脚，这样慢慢的就能够将身体里面的湿气给去除。要注意用艾叶泡脚，不要直接兑凉水，最好用艾叶直接煮水，煮开之后放到适合的温度，将脚放到里面去泡一泡即可。
        </p>
        <p>5、劳逸结合</p>
        <p>
          过度劳累容易损耗到气血，气血不足则会加重体内的湿气，所以春季想要去除身体湿气，一定要注意劳逸结合，保持充足的睡眠，有条件的可以中午的时候睡一小会，这样大脑跟身体都可以得到充分的休息，既能够提高工作效率，又有利于身体的健康。
        </p>
        <p>
          另外，春季除湿还可以使用姜汁来发汗，如果感染了风寒或者是淋了雨，可以喝一点姜汁，这样就能有效的驱散身体的湿气，将身体里面的水气给直接逼出来，而且生姜能够健脾养胃，所以春季应该多吃姜。
        </p> -->

        <div class="mask555"></div>
        <div class="more">余下内容为付费内容，需付费后继续阅读</div>
        <div class="buy" @click="toPay">立即支付</div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Nav from "@/components/nav/nav.vue";
import Bottom from "@/components/bottom/bottom.vue";
export default {
  components: { Nav, Bottom },

  Navdata() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    toPay() {
      this.$router.push({ name: "pay" });
    },
  },
};
</script>
<style lang='less' src="./index.less"  >
</style>