import Vue from 'vue'
import axios from 'axios'
// import store from '../store'
// import {
// 	ACCESS_TOKEN,
// 	USER_FORM,
// } from "../store/mutation-types.js"
console.log('我是net.js')
import {
	Toast
} from 'vant'
// import {
//     USERWHITE
// } from './whiteUrlList.js'
//创建实例
const NET = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	timeout: 9000,
	withCredentials: true, // 允许携带cookie  表示跨域请求时是否需要使用凭证
	// auth: {
	// 	'X-Access-Token': localStorage.getItem('token') || '',
	// }
	headers: {
		//'Content-Type': 'application/json;charset=UTF-8;',
		//"Content-Type": "multipart/form-data"
		// 	'X-Access-Token': localStorage.getItem('token') || ''
	},
})

let loading;
let requestToast = null
//加载loading
const showLoading = () => {
	if (!loading) {
		requestToast = Toast.loading({
			message: '加载中...',
			forbidClick: true,
			duration: 0, //不消失
		})
		loading = true
		//store.commit('showLoading')
	}
}

//隐藏loading
const hideLoaing = () => {
	loading && requestToast.clear(), loading = false;
}

//拦截请求
NET.interceptors.request.use(config => {
	let { url } = config
	// const token = Vue.ls.get(ACCESS_TOKEN)
	// if (token) {
	// 	config.headers['X-Access-Token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
	// }

	// if(!USERWHITE.includes(config.url)){
	// 	//拦截请求
	// 	showLoading()
	// }
	
	//禁止调接口
	return config;
}, err => {
	//console.log(err)
	return Promise.reject(err)
})

//响应拦截器
NET.interceptors.response.use(res => {
	let {
		code,
		message
	} = res.data

	hideLoaing()
	if (code === 200) {
		return res.data;
	} else {
		if (code === 4001 || code == 1001) {
			Toast(message)
		} else {
			Toast.fail({
				message,
				type: 'error',
				duration: 3 * 1000
			})
		}
		return Promise.reject(res.data)
	}

}, err => {
	//响应错误
	hideLoaing()
	if (!err?.response) {
		//Toast('网络超时!');
		return Promise.reject('network error')
	}
	// console.log("request: ", err.request);
	let {
		status,
		data
	} = err.response
	if (data && status) {
		//网络错误 错误码:${status} -
		Toast(`${data.message}`)
	}
	// switch (status) {
	// 	case 401:
	// 		// 401 清除token信息并跳转到登录页面
	// 		if (/wxwork|dingtalk/i.test(navigator.userAgent)) {
	// 			Vue.prototype.$Jmessage.loading('登录已过期，正在重新登陆', 0)
	// 		} else {
	// 			Vue.ls.remove(ACCESS_TOKEN)
	// 			let userForm = Vue.ls.get(USER_FORM)
	// 			if (userForm == 'sales') {
	// 				store.dispatch('sales/SalesLogout').then(() => {
	// 					location.replace(location.origin + '/h5/login')
	// 				})
	// 			} else if (userForm == 'customer') {
	// 				//store.dispatch('Logout')
	// 				if (store.state.user.loginStatus == false) {
	// 					store.commit('SET_LOGIN_STATUS', 0)
	// 					Vue.ls.set(SET_LOGIN_STATUS, 0)
	// 				} else {
	// 					store.commit('SET_LOGIN_STATUS', false)
	// 					Vue.ls.set(SET_LOGIN_STATUS, false)
	// 				}
	// 				location.replace(location.origin + '/h5/home');
	// 			} else if (userForm == 'doctor') {
	// 				store.dispatch('doctor/docotorLogout').then(() => {
	// 					location.replace(location.origin + '/h5/login?activeIndex=1')
	// 				})
	// 			}
	// 			try {
	// 				// let path = window.document.location.hash
	// 				// console.log('location pathname -> ' + path) path != '/' &&
	// 			} catch (e) {
	// 				//店员端过期
	// 				// if (!flag) location.replace(location.origin + '/h5/home');
	// 			}
	// 		}
	// 		break;
	// }
	return Promise.reject(err);

})

export default NET
