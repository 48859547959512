<template>
  <div>
    <div class="container m-contact">
    	<!--banner图片区-->
    	<div class="banner"></div>

    	<!--内容文字区-->
    	<div class="contact-article">
				<div class="title">
					<p>联系我们</p>
				</div>
    		<div class="contact-content">
						<div class="item">
							<p>服务热线</p>
							<span>4006099865</span>
						</div>
						<div class="item">
							<p>公司地址</p>
							<span>上海市徐汇区钦州路100号2号楼102室</span>
						</div>
						<div class="item">
							<p>招聘相关</p>
							<span>hr@dushengtech.com</span>
						</div>
				</div>
    	</div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  components: {

  },
  mounted() {

  },
  methods: {

  },
}

</script>
<style scoped lang='less'>
@charset "utf-8";* {
    box-sizing: border-box
}

body {
    font-family: 'PingFangSC-Thin','Microsoft YaHei light'
}

.container {
    width: 1200px;
    margin-left: auto;
    margin-right: auto
}

.m-contact .banner {
    width: 100%;
    height: 352px;
    text-align: center;
    background-image: url('@/assets/newImages/banner22.jpg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 70px;
}

.m-contact .banner img {
    width: 100%
}

.m-contact .contact-article {
    padding-top: 10px
}

.m-contact .contact-article .title {
    position: relative;
    margin-bottom: 74px;
    width: 100%;
    height: 45px;
    background: url('../../../assets/newImages/title-pic@2x.png') no-repeat;
    background-size: contain
}

.m-contact .contact-article .title p {
    font-family: PingFangSC-Light,PingFang SC;
    position: absolute;
    color: #202020;
    font-size: 44px;
    font-weight: 300;
    bottom: 4px
}

.m-contact .contact-article .contact-content {
    display: flex;
    justify-content: space-between
}

.m-contact .contact-article .contact-content .item {
    margin-bottom: 40px
}

.m-contact .contact-article .contact-content .item+.item {
    border-left: 1px #E5E7EB solid;
    padding-left: 31px
}

.m-contact .contact-article .contact-content .item p {
    font-family: PingFangSC-Medium,PingFang SC;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #1B1B1B;
    line-height: 32px
}

.m-contact .contact-article .contact-content .item span {
    font-family: PingFangSC-Thin,PingFang SC;
    font-size: 20px;
    color: #1B1B1B;
    line-height: 32px;
    font-weight: 100
}

.m-contact .smile {
    padding: 60px 0 80px;
    text-align: center
}

.m-contact .smile img {
    width: 92px
}

.g-layout-content {
    padding-bottom: 50px
}

</style>