<template>
  <div>
    <Nav></Nav>
    <div class="banner"></div>
    <div class="message">
      <div class="message_wrapper">
        <div class="showBox">
          <van-form validate-first @failed="onFailed">
            <van-field v-model="username" name="用户名" label="用户名" placeholder="请输入用户名"
              :rules="[{ required: true, message: '请输入用户名' }]" />
            <van-field v-model="value1" label="手机号" name="pattern" placeholder="请输入手机号"
              :rules="[{ pattern, message: '请输入正确内容' }]" />
            <van-field v-model="sms" center clearable label="短信验证码" placeholder="请输入短信验证码">
              <van-button id="noo" slot="button" size="small" type="primary">发送验证码</van-button>
            </van-field>
            <van-field v-model="password1" type="password" name="密码" label="密码" placeholder="密码"
              :rules="[{ required: true, message: '请输入密码' }]" />
            <van-field v-model="password2" name="确认密码" type="password" label="确认密码" placeholder="请输入确认密码"
              :rules="[{ required: true, message: '请输入确认密码' }]" />
            <van-field v-model="name" name="公司名称" label="公司名称" placeholder="请输入公司名称"
              :rules="[{ required: true, message: '请输入公司名称' }]" />
            <van-field v-model="address" name="公司地址" label="公司地址" placeholder="请输入公司地址"
              :rules="[{ required: true, message: '请输入公司地址' }]" />
            <van-field v-model="location" name="公司所在地" label="所在地" placeholder="请输入公司地址"
              :rules="[{ required: true, message: '请输入公司所在地' }]" />
            <van-checkbox-group v-model="checkboxGroup" direction="horizontal" class="password_text">
              <div style="margin-top: 16px;">
                <van-checkbox name="2" shape="square">我已认真阅读并同意签署<a @click="service">《平台服务协议》</a>
                </van-checkbox>
              </div>
            </van-checkbox-group>
            <div style="margin: 16px;">
              <van-button round block type="info" native-type="submit">立即入驻</van-button>
            </div>
          </van-form>
        </div>
      </div>
    </div>
     <div class="service" v-if="show">
        <!-- <van-cell is-link @click="showPopup">展示弹出层</van-cell> -->
        <div class="service_1">
          <van-popup v-model="show" round  closeable :style="{ height: '80%' }">
            <div style="margin:100px,50px ;">
              <h3 style="margin-top: 20px;margin-bottom: 20px;">度生健康平台服务协议</h3>
              <div class="content" style="margin-bottom: 20px;padding: 0 20px;">
                &emsp;&emsp;提示条款：欢迎您签署《度生健康平台服务协议》（以下简称“本协议”），享受度生健康平台（度生信息科技（上海）有限公司及其关联方，以下简称“本平台”或“度生健康”）相关服务。本协议双方为本平台与申请会员（以下简称“您”）。
                本平台实行会员制，您在通过本平台享受会员服务前，均应先仔细阅读本协议中全部的规则内容，并在完成身份验证、付费等相关程序后方可享受本平台提供的相关服务。您通过网络页面点击确认接受本协议，即视为您与本平台已达成合意，对本协议、各项服务规则均已知晓、理解并接受，并已同意将其作为确定双方权利义务的依据。在您确认接受本协议前，请您务必审慎阅读并充分理解本协议各条款的内容，尤其是以加粗方式标示出的关于本平台服务及用户重大权益的规则，该规则可能涉及相关方的责任免除或限制、法律适用与争议解决条款，请您重点阅读。在您确认接受本协议全部条款之前，请勿进行下一步操作。<br>
                本协议各条款前所列索引关键词仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。为维护您自身权益，建议您仔细阅读各条款具体表述。<br>
                一、 定义<br>
                会员：是指完成了成为会员的所有程序，且在遵守本协议的前提下，在会员有效期内享受本平台服务的自然人。</br>
                本平台服务：是指本平台基于互联网，以包含微信平台、H5等在内的各种形态（包括未来技术发展出现的新的服务形态）向您提供的各项服务，具体服务内容以本协议第四条及本平台公布的信息为准。</br>
                本平台规则：是指本平台已发布或将来可能发布的各种规范性文件，包括但不限于协议、协议附件、细则、服务规则、解读、公告等内容以及与本平台有关的各类规则、实施细则、产品流程说明、公告等。</br>
                二、协议范围</br>
                2.1
                您确认，在您开始注册使用本平台时，已具备中华人民共和国相关法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则应在您监护人知悉并同意的情况下注册、使用平台服务。您在注册成功本平台后，即视为满足上述条件，且对于本平台的服务具有真实的购买意愿，您及您的监护人应依照法律规定承担因此而导致的一切后果。此外，您还需确保您不是任何国家、国际组织或者地域实施的贸易限制、制裁或其他法律、规则限制的对象，否则您可能无法正常注册及使用本平台服务。</br>
                2.2
                由于互联网高速发展，您与本平台签署的本协议列明的条款并不能完整罗列并覆盖您与本平台所有权利与义务，现有的约定也不能保证完全符合未来发展的需求，因此，您理解并同意，为了更好地为您提供服务，本平台将对本协议或各项服务规则随时进行修改更新，一旦本协议内容发生变动，本平台运营方将会在本平台上公布修改之后的协议内容，或向用户通知修改内容。此外，本平台法律声明、授权协议以及本平台不时公布的规则、说明等均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您在协议变更或相关声明、规则、说明等发布后继续使用本平台服务，视为您同意上述修改及发布。</br>
                三、成为会员与使用</br>
                3.1 会员账号说明</br>
                3.1.1 当您按照相关页面提示填写并提交信息、阅读并同意本协议、付费且完成全部程序后，您成为本平台会员，会员服务即时生效。会员的有效期以本平台公布的期限或您选择的期限为准。</br>
                3.1.2
                成为会员后，您有权利不接受本平台的服务，并可申请取消会员服务，若您在最近一次购买付费服务且七日内未使用与此付费服务相关福利的，待您的取消申请通过后，本平台将退还会员期内最近一次已收取的服务费用。</br>
                3.1.3
                您需要使用您的手机号（以下称“账号”）登录本平台。由于您的账号关联您的个人信息及本平台商业信息，您的账号仅限您本人使用。未经本平台同意，您直接或间接授权第三方使用您账号或获取您账号项下信息的行为无效。</br>
                3.1.4 您的账号不得以任何方式转让，否则本平台有权追究您的违约责任，并有权在未经通知的情况下取消转让账号及受让账号的会员资格，且由此产生的一切责任均由您承担。</br>
                3.2 账号信息管理</br>
                3.2.1 在使用本平台服务时，您应当按本平台页面的提示准确完整地提供您的信息（包括您的姓名、身份证件号码、联系电话、联系地址等）。</br>
                3.2.2
                如您提供的信息变更，您应当及时予以更新，在法律有明确规定或有权机关要求本平台作为平台服务提供者必须对部分会员的信息进行核实的情况下，本平台将依法不时地对您的信息进行检查核实，您应当配合提供最新、真实、完整、有效的信息。</br>
                3.2.3 作为本平台经营者，为使您更好地使用本平台服务，保障您的账号安全，本平台有权要求您按我国法律规定完成实名认证。</br>
                3.3 账号安全规范</br>
                3.3.1
                您有义务妥善保管注册/登陆本平台的手机号码、账号、密码、数字证书（如有）、手机动态口令（如有）、账号绑定的手机号码，与本平台账号关联的银行账户、卡号、密码、开户行预留的手机号码，及来自于本平台及/或开户行向您发送的校检码等与本平台账号与本平台关联的银行账户有关的一切信息和设备。如您更换、暂停或终止使用上述信息和/或设备，您应及时通知本平台并及时联系本平台进行解绑、变更或注销处理。</br>
                3.3.2
                请您确认，本平台任何时候均不会主动要求您提供您的账号密码。因此，您应对您的账号采取必要和有效的保密措施，并确保您每次结束时退出登录并以正确步骤离开本平台。账号因您保管使用不当、主动泄露、遭受他人攻击、诈骗或其他非本平台原因导致的损失及后果，本平台并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。</br>
                3.3.3
                如发现任何未经授权使用您账号登录本平台或其他可能导致您账号遭窃、遗失的情况，建议您立即通知本平台。您理解本平台对您的任何请求采取行动均需要合理时间，且本平台应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大。</br>
                3.3.4
                如本平台根据本平台规则中约定的违约认定程序及标准判断您账号的使用可能危及您的账号安全及/或本平台信息安全的，本平台可拒绝提供相应服务或终止本协议。若因此而给本平台造成损失的，本平台有权向您主张赔偿。</br>
                四、本平台服务</br>
                4.1
                成为本平台会员后，您在会员有效期内可享受的会员权益以本平台公布的信息为准。为更好的向会员提供服务，本平台基于自身业务发展需要可调整全部或部分会员权益。本平台就前述权益调整将在相应服务页面进行通知或公告，您可以通过本平台查询最新的会员权益内容。</br>
                4.2
                您在本平台可以享受本平台为您提供的各项会员福利，其中在您使用会员健康相关福利时，您可以通过线上明示授权的方式勾选本《平台服务协议》及附件1《知情同意书》和附件2《保险理赔授权书》后即可使用该项服务，勾选后您授权同意本平台作为投保人为您投保特定团体医疗保险并代为领取相应的保险金。您确认并知悉，保险服务的保单生效日期由合作的保险公司最终签发的电子保单上的日期为准。如因您的年龄、职业、健康状况或其他原因造成保险公司不予承保的，您仍然可以使用会员健康相关福利而无需支付额外的费用，相应的药品款项将由本平台予以全额垫付，您已支付的款项亦将直接冲抵前述本平台为您垫付的药品款项。</br>
                4.3
                您理解，本平台提供健康相关福利不可被用于经营、营利或其他非您本人治疗所需的目的，基于正常、持续、安全运营的考虑，本平台可能会对您在一定期限内使用健康相关福利的次数设置上限。同时，本平台将根据实际运营情况、自身承受能力等因素不时调整运营策略，可能会据此调整、中断或终止健康相关福利。</br>
                4.4
                本平台作为信息提供平台仅提供相关商品及/或服务的信息展示服务，不介入您与商品及/或服务提供方的具体交易，您需要自行判断并承担您与商品及/或服务提供方之间交易的风险，如您有关于商品及/或服务的质量、价格、售后、退货、退款等方面的问题和需求，您需要自行与商品及/或服务的提供方协商解决。</br>
                4.5
                本平台将通过依法建立相关检查监控制度尽可能保障您在本平台上的合法权益及良好体验。同时，鉴于本平台具备存在海量信息及信息网络环境下信息与实物相分离的特点，本平台无法逐一审查商品及/或服务的信息，无法逐一审查交易所涉及的商品及/或服务的质量、安全以及合法性、真实性、准确性，对此您应谨慎判断。</br>
                五、会员信息保护</br>
                本平台非常重视会员个人信息（即能够独立或与其他信息结合后识别会员身份的信息）的保护，在您使用本平台服务时，您同意本平台按照在本平台上公布的《隐私政策》收集、存储、使用、披露和保护您的个人信息。本平台希望通过《隐私政策》向您清楚地介绍本平台对您个人信息的处理方式，因此本平台建议您完整地阅读《隐私政策》，以帮助您更好地保护您的个人信息。</br>
                六、违约处理特别约定</br>
                6.1
                如您以非真实的方式骗取并使用本平台服务的，本平台可立即终止与您的所有合作并向您收取违约金及/或赔偿金，该等金额以本平台因您的违约行为而遭受的经济损失和商誉损失作为计算依据，并有权依法追究您法律责任。</br>
                6.2 如您因严重违约导致本平台终止本协议的，出于维护平台秩序及保护消费者权益的目的，本平台及/或其关联方可对与您在其他协议项下的合作采取中止甚或终止协议的措施。</br>
                七、知识产权</br>
                7.1
                本平台所展示的各运营体系均由本平台自主开发、运营提供技术支持，并对本平台服务的开发和运营等过程中产生的所有数据和信息享有全部权利。本平台提供各项服务时所依托软件的著作权、专利权、所使用的各项商标、商业形象、商业标识、技术诀窍，其著作权、商标权及其他各项权利均归本平台所有。</br>
                7.2
                本平台所包含的全部智力成果包括但不限于数据库、网站设计、文字和图表、软件、照片、录像、音乐、声音及其前述组合，软件编译、相关源代码和软件（包括小程序和脚本）的知识产权权利均归本平台或相关权利人所有。您不得以商业目的复制、更改、拷贝、发送或使用前述任何材料或内容。</br>
                7.3 本平台名称中包含的所有权利（包括商誉和商标）均归本平台或及关联公司所有。</br>
                7.4 您在使用本平台服务过程中不得非法使用或处分本平台或他人的知识产权权利。</br>
                八、法律适用及管辖</br>
                8.1
                本协议的订立、效力、解释、履行及争议的解决均适用中华人民共和国法律。若本协议之任何规定因与中华人民共和国的法律抵触而无效或不可执行，则这些条款将尽可能按照接近本协议原条文意旨重新解析，且本协议其它规定仍应具有完整的效力及效果。</br>
                8.2
                因本协议引起的或与本协议有关的任何争议，应首先通过协商方式解决。协商不成的，应将争议提交至本协议签订地通过诉讼方式解决。如诉讼事项不影响合同其它部分的履行，则在诉讼期间，除正在进行诉讼的部分外，本合同的其它部分应继续执行。</br>
                九、服务终止与协议的终止</br>
                9.1 本平台有权依据本协议约定注销您的账号，本协议于账号注销完成之日终止。</br>
                9.2 本平台可以终止全部平台服务，本协议于本平台全部服务依法定程序方式终止之日终止。该情况下，本平台将按照法律法规的规定提前三十日在本平台首页显著位置持续公示有关信息。</br>
                9.3 本协议终止后，您理解并同意本平台不再继续向您提供服务或履行其他义务。</br>
                9.4 本协议的终止不影响守约方向违约方追究违约责任。</br>
                十、其他</br>
                10.1.本平台有权随时对本协议进行修改，并将在相关页面上提示修改的内容；如果您不同意本协议的修改，可以取消已经获取的服务并停止使用；如果您继续使用本平台服务，则视为您已经接受本协议的全部修改。本协议的修改一经公布即视为通知您。</br>
                10.2 您除需遵守该本协议及本协议的补充协议外，仍应同时履行《服务协议》、《法律声明》、《隐私政策》等的相关约定。</br>

                协议生效时间：</br>

                度生信息科技（上海）有限公司</br>
              </div>

            </div>
          </van-popup>
        </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Bottom from "../../components/bottom/bottom.vue";
import Nav from "../../components/nav/nav.vue";
export default {
  data() {
    return {
      value1: "",
      username: "",
      password: "",
      password1: "",
      password2: "",
      sms: "",
      name: "",
      address: "",
      location: "",
      checkboxGroup: [],
      pattern:
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      show: false,
    };
  },
  components: {
    Bottom,
    Nav,
  },
  mounted() {},
  methods: {
    onFailed(errorInfo) {
      console.log("failed", errorInfo);
    },
    service() {
      this.show = true;
    },
  },
};
</script>

<style scoped lang='less' src="./index.less" scoped>
</style>