<!--  -->
<template>
  <div class="one">
    <Nav></Nav>
    <div class="content">
      <div class="title">吃冰伤胃还是吃辣伤胃？</div>
      <div class="introduce">
        <span>文章来源:佚名 </span>
        <span>2021-03-32 14:17:34</span>
      </div>
      <div class="line"></div>
      <div class="msg">
        <p>1.吃辛辣食物</p>
        <p>
          辣椒本身并不伤胃，只不过其中的辣椒素会激活痛觉神经，让人感觉火辣辣的。但是这种痛并不会带来实质性的伤害。
        </p>
        <p>
          其实，辣椒素对胃黏膜有一定的保护作用。辣椒素刺激胃黏膜分泌更多的黏液，使黏液层更厚，能降低胃病的一些发病率，也能使得胃局部的修复功能增加。
        </p>
        <p>
          但凡事都要有度，当辣度明显超出身体承受能力的时候，身体会通过腹泻、呕吐把辣椒素尽快排出，这一系列动作才会对身体造成实质性的伤害。
        </p>
        <p>如果你是胃病患者，那么忌辣是一定要的。</p>
        <p>
          对于原本就有胃病的患者来说，胃部早已经存在了不同程度的炎症损伤等创面。这种情况下，只要吃一点辣椒，“辣”就会直接刺激创创伤面面，导致病情加重。
        </p>
        <p>2.吃冰冷的食物</p>
        <p>很多人都会觉得刺激性强的食物会伤胃，比如吃凉的。</p>
        <p>
          其实，胃有一套灵活的神经内分泌调控机制，可以维护胃里的环境稳定，降低外来因素的影响。不管是冷的还是热的，胃内的温度变化都在0.3°C内。
        </p>
        <p>建议</p>
        <p>1.吸烟喝酒</p>
        <p>酒精能够穿透胃黏膜，对胃造成急性伤害，导致胃黏膜糜烂出血。</p>
        <p>
          而烟草中有多重对胃有毒的物质。比如尼古丁会刺激胃黏膜血管收缩，导致胃黏膜出血，还能在胃酸作用下
        </p>

        <div class="mask"></div>
        <div class="more">余下内容为付费内容，需付费后继续阅读</div>
        <div class="buy" @click="toPay">立即支付</div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Nav from "@/components/nav/nav.vue";
import Bottom from "@/components/bottom/bottom.vue";
export default {
  components: { Nav, Bottom },

  Navdata() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    toPay() {
      this.$router.push({ name: "pay" });
    },
  },
};
</script>
<style lang='less' src="./index.less"  >
</style>