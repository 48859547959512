<template>
  <div>
    <div class="nav">
      <div class="nav_1">
        <div class="imgbox" @click="toHome">
          <img src="@/assets/images/1.png" class="navimg" />
        </div>
        <div class="textbox">
          <span class="text" v-for="(item,index) in navList" :key="index" @click="togo(index,item)">
            <a :class="navIndex == index ? 'active' : '' " href="javascript:void(0)">
              {{ item.title }}
              <ul v-if="item.children">
                <li :class="item1.active?'active':''" v-for="(item1,index1) in item.children" :key="index1" @click="togoChild(index,index1)">{{ item1.text }}</li>
              </ul>
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      loginshow: false,
      registershow: false,
      value1: "",
      username: "",
      password: "",
      password1: "",
      password2: "",
      sms: "",
      name: "",
      idnumber: "",
      checkboxGroup: [],
      pattern:
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      namepattern: /^[\u2E80-\u9FFF]+$/,
      idpattern:
        /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
      show: false,
      navIndex:0,
      navList:[
        { title:'首页',path:'/' },
        { 
          title:'产品介绍',
          children:[
            {text:'全病程管理',active:false,path:'/text'},
            // {text:'云药房商城',active:false,path:'/text'},
            {text:'专病专保-胃家保',active:false,path:'/text'},
            {text:'专病专保-软骨新生',active:false,path:'/text'},
            {text:'专病专保-保列久安',active:false,path:'/text'},
            {text:'专病专保-爱巢保',active:false,path:'/text'},
          ] 
        },
        { 
          title:'关于度生',
          children:[
            {text:'公司介绍',active:false,path:'/introduction'},
            // {text:'发展历程',active:false,path:'/history'},
            // {text:'所获荣誉',active:false,path:'/honor'},
            {text:'联系我们',active:false,path:'/contact'}
          ]
        },
        
        { title:'患教科普',path:'/news' },
        // { title:'加入我们',path:'/' },
      ]
    };
  },
  components: {},
  
  watch: {
    $route: {
      handler: function(val, oldVal){
        let arr = [['index'],['text'],['introduction','history','honor','contact'],['news','newsDetail']]
        for(let i = 0 ;  i < 4 ; i++ ){
          if(arr[i].includes(val.name)){
            this.navIndex = i
          }
        }
        // console.log(this.navIndex,val)
        if(this.navIndex == 1){
          this.$nextTick(()=>{
            this.navList.map((i)=>{
              if(i.children){
                i.children.map((item)=>{
                  item.active = false
                })
              }
            })
            this.navList[1].children[Number(val.query.index) - 1].active = true
          })
        }
      },
      // 深度观察监听
      deep: true,
      immediate:true
    }
  },
  mounted() {
    localStorage.setItem("loginshow", this.loginshow);
    localStorage.setItem("registershow", this.registershow);
  },
  methods: {
    toLgoin() {
      this.loginshow = true;
    },
    register() {
      this.registershow = true;
      this.loginshow = false;
    },
    Login() {
      this.registershow = false;
      this.loginshow = true;
    },
    toRegister() {
      this.registershow = true;
    },
    onFailed(errorInfo) {
      console.log("failed", errorInfo);
    },
    togoChild(index,index1){
      // this.navIndex = index
      this.navList.map((i)=>{
        if(i.children){
          i.children.map((item)=>{
            item.active = false
          })
        }
      })
      this.navList[index].children[index1].active = true
      // console.log(this.navList[index].title)
      if(this.navList[index].title == '产品介绍'){
        this.$router.push({ 
          path: this.navList[index].children[index1].path,
          query: {index: index1+1}
        });
      }else{
        this.$router.push({ path: this.navList[index].children[index1].path });
      }
      
    },
    togo(item,data) {
      if(data.children){
        return
      }else{
        // this.navIndex = item
        this.$router.push({ path: data.path });
      }
      
      return false
      if (this.$route.path == "/") {
        var serve = document.getElementById(item);
        const top = serve.offsetTop;
        window.scrollTo({ top, behavior: "smooth" });
      } else {
        this.$router.push({ path: "/" });
        setTimeout(() => {
          var serve = document.getElementById(item);
          const top = serve.offsetTop;
          window.scrollTo({ top, behavior: "smooth" });
        }, 200);
      }
    },
    toHome() {
      if (this.$route.path !== "/") {
        this.$router.push({ path: "/" });
      }
    },
    showPopup() {
      this.show = true;
    },

    service() {
      this.show = true;
      // Dialog.alert({
      //   title: '度生健康平台服务协议',
      //   message: '弹窗内容',
      // }).then(() => {
      //   // on close
      // });
    },
  },
};
</script>
<style scoped lang='less'>
.nav {
  width: 100%;
  height: 70px;

  .nav_1 {
    position: relative;
    width: 1200px;
    height: 70px;
    margin: 0 auto;
    // background-color: pink;

    .imgbox {
      position: absolute;
      width: 138px;
      height: 30px;
      left: 0px;
      top: 20px;
      cursor: pointer;

      .navimg {
        width: 100%;
        height: 100%;
      }
    }

    .textbox {
      position: absolute;
      left: 338px;
      top: 24px;
      // width: 232px;
      font-size: 16px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: row;

      .text {
        display: inline-block;
        position: relative;
        padding: 0px 20px;
        // width: 64px;
        // height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #323233;
        line-height: 22px;
        font-size: 16px;
        text-align: left;
        white-space: nowrap;
        line-height: 22px;
        // margin-right: 45px;
        a{
          padding: 10px 0px;
          box-sizing: border-box;
          position: relative;

          ul{
            position: absolute;
            display: none;
            // width: 100%;
            top: 42px;
            left: calc(50% - 90px);
            padding-top: 10px;
            background-color: #fff;
            z-index: 100;
            text-align: center;
            li{
              background-color: #fff;
              color: #323233;
              width: 180px;
              height: 100%;
              line-height: normal;
              padding: 10px 20px;
              box-sizing: border-box;
            }
            li.active{
              color: #007AFF;
            }
            li:hover{
              color: #007AFF;
            }
          }
        }

        a::after{
          content: '';
          display: block;
          width: 0;
          height: 2px;
          background-color: #007AFF;
          position: absolute;
          bottom: 0;
          left: 0;
          transition: all .3s;
          -webkit-transition: all .3s;
          -o-transition: all .3s;
          -moz-transition: all .3s;
          -ms-transition: all .3s;
        }
        a:hover{
          color: #007AFF;
          ul{
            display: block;
          }
        }
        a:hover::after{
          width: 100% !important;
        }

        .active{
          color: #007AFF;
        }
        .active::after{
          width: 100% !important;
        }
        
      }

      .text-wrapper_1 {
        position: absolute;
        left: 282px;
        top: -10px;
        width: 24px;
        height: 14px;
        background: #c92d2d;
        border-radius: 2px;
        text-align: center;
        line-height: 14px;

        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }

        .text_4 {
          font-size: 9px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }

    .cart {
      position: absolute;
      left: 958px;
      top: 25px;
      width: 80px;
      height: 20px;
      display: flex;
      justify-content: space-between;

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      .cart_2 {
        // width: 48px;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #323233;
        line-height: 22px;
      }

      .cart_1 {
        position: absolute;
        right: -17px;
        top: -5px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        text-align: center;
        line-height: 15px;
        background: #faab0c;
        color: white;
      }

      .cartbox {
        width: 48px;
        height: 22px;
      }
    }

    .login {
      position: absolute;
      left: 1109px;
      top: 24px;
      width: 91px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #323233;
      line-height: 22px;
      display: flex;
      justify-content: space-between;
    }
  }

  .cart {
    position: absolute;
    left: 1318px;
    top: 25px;
    width: 80px;
    height: 20px;
    display: flex;
    justify-content: space-between;

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    .cart_2 {
      // width: 48px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #323233;
      line-height: 22px;
    }

    .cart_1 {
      position: absolute;
      right: -17px;
      top: -5px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      text-align: center;
      line-height: 15px;
      background: #faab0c;
      color: white;
    }

    .cartbox {
      width: 48px;
      height: 22px;
    }
  }

  .login {
    position: absolute;
    left: 1469px;
    top: 24px;
    width: 91px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #323233;
    line-height: 22px;
    display: flex;
    justify-content: space-between;
  }

  .health {
    background-image: url("../../assets/images/2.png") no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 550px;
    background-color: pink;
  }

  .text_11 {
    width: 200px;
    height: 70px;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 50px;
    text-align: left;
    white-space: nowrap;
    line-height: 70px;
    margin: 188px 860px 292px 860px;
  }

  .text_12 {
    width: 360px;
    height: 33px;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 24px;
    text-align: left;
    white-space: nowrap;
    line-height: 33px;
    margin: 6px 0 253px 780px;
  }
}

.register {
  .show {
    width: 822px;
    height: 706px;
    background: #ffffff;
    border-radius: 20px;
  }

  .showBox {
    width: 65%;
    height: 500px;
    margin-bottom: 165px;
  }
}

.show {
  display: flex;
  justify-content: center;
  align-items: center;

  .showBox {
    width: 65%;

    .van-field__label {
      width: 7.2em !important;
    }

    .login_text {
      margin-bottom: 40px;
    }

    .password_text {
      margin-top: 20px;
      position: relative;

      .misspas {
        position: absolute;
        right: 0;
      }
    }
  }
}

.service {
  height: 1000px;
  width: 600px;
  text-align: center;

  // text-align: center;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  /deep/.van-popup {
    /deep/.title {
      margin: 10px, 0;
    }

    .content {
      text-align: left;
      padding: 0, 10px;
    }
  }
}
</style>