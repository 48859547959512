<!--  -->
<template>
  <div class="big">
    <Nav></Nav>
    <div class="wrapper">
      <div class="pay">
        <div class="title">正在使用即时到账交易</div>
        <div class="introduce">收款方：度生信息科技（上海）有限公司</div>
        <div class="content">
          <div class="one">微信扫一扫付款</div>
          <div class="two">
            <span class="two_1">2.0</span>
            <span class="two_2">元</span>
          </div>
          <div class="paycontent">
            <div class="imgs"></div>
            <div class="pay2">
              <div class="imgs2"></div>
              <div class="msg2">
                <span>打开微信 扫一扫支付</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Nav from "@/components/nav/nav.vue";
import Bottom from "@/components/bottom/bottom.vue";
export default {
  components: { Nav, Bottom },

  Navdata() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>
<style lang='less' src="./index.less"  >
</style>