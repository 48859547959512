<template>
  <div>
    <div class="med-history">
        <div>
            <div class="med-carousel">
                <div class="carousel-left">
                    <div class="logo"></div>
                </div>
                <div class="carousel-middle">
                    <div class="split-line"></div>
                    <div class="buttons">
                        <div class="top-arrow" @click="top"></div>
                        <div class="circle"></div>
                        <div class="bottom-arrow" @click="bottom"></div>
                    </div>
                </div>
                <div class="carousel-right">
                    <div class="carousel-items" :style="`transform: translateY(${ 315 - index * 70}px);`">
                        <div v-for="(item,index1) in list" :key="index1" @click="select(index1)" :class="`item item-${item.height}  ${index1 == index ? 'item-selected':''}`" >
                            <div class="time">{{ item.time }}</div>
                            <div class="text">{{ item.text }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list:[
        {
          time:'2014年06月',
          text:'成立于成都',
          height:'70'
        },
        {
          time:'2014年11月',
          text:'度生APP正式上线，主打医生社交、学术交流',
          height:'70'
        },
        {
          time:'2015年01月',
          text:'陆续推出“知识库”、“医生小组”等功能、打造内容丰富的医生学术平台',
          height:'70'
        },
        {
          time:'2016年10月',
          text:'度生APP4.0发布，医生社交平台正式升级为医生服务平台，互联网医院雏形建立',
          height:'70'
        },
        {
          time:'2017年03月',
          text:'度生互联网医院开放平台正式上线，涵盖在线问诊、检验检查、在线购药等七大核心模块',
          height:'70'
        },
        {
          time:'2017年08月',
          text:'首批获得互联网医院牌照，实现医生互联网备案，多点执业',
          height:'70'
        },
        {
          time:'2018年04月',
          text:'度生开设首个互联网医院科室：感染科，同期启动自主研发的丙肝患者管理体系',
          height:'70'
        },
        {
          time:'2018年10月',
          text:'首位丙肝患者完全治愈出院',
          height:'70'
        },
        {
          time:'2019年09月',
          text:'度生开启全面的疾病管理模式，覆盖肿瘤、心脑血管、糖尿病、呼吸、慢性肾病、精神等领域，并自建医助、健管师团队，首创业内多岗运营模式',
          height:'100'
        },
        {
          time:'2020年02月',
          text:'成为国内首批打通医保在线支付的互联网医院（成都）',
          height:'70'
        },
        {
          time:'2020年03月',
          text:'度生推出8套慢病管理金标准及SOP，并启动多学科专家共识建设',
          height:'70'
        },
        {
          time:'2020年12月',
          text:'度生慢病管理体系及科研数据体系通过ISO9001国际质量体系认证',
          height:'70'
        },
        {
          time:'2021年05月',
          text:'发布中国首个《互联网医院糖尿病线上管理中国专家共识》',
          height:'70'
        },
        {
          time:'2021年06月',
          text:'成立【度生学术委员会】，程书钧院士、刘力生教授任首席顾问专家',
          height:'70'
        },
        {
          time:'2021年07月',
          text:'发布首个《肝癌患者常见药物治疗相关不良反应互联网管理专家指导意见》',
          height:'70'
        },
        {
          time:'2021年12月',
          text:'度生参与的《互联网医院开展艾滋病相关医疗服务专家共识》刊发于中国科技核心期刊《中国艾滋病性病》杂志',
          height:'100'
        },
        {
          time:'2022年06月',
          text:'北京地区医保支付功能上线',
          height:'70'
        },
        {
          time:'2022年08月',
          text:'度生APP9.0上线，创新搭载“度生天使引擎”',
          height:'70'
        },

      ],
      index:5
    }
  },
  components: {

  },
  mounted() {

  },
  methods: {
    select(index){
      this.index = index
    },
    bottom(){
      if(this.index === this.list.length - 1){
        return
      }
      this.index = this.index + 1
    },
    top(){
      if(this.index === 0){
        return
      }
      this.index = this.index - 1
    }
  },
}

</script>
<style scoped lang='less'>
@charset "utf-8";* {
    box-sizing: border-box
}

body {
    font-family: 'PingFangSC-Thin','Microsoft YaHei light'
}

@font-face {
    font-family: "mediconfont";
    src: url('//at.alicdn.com/t/font_bomhhb0ckjz41jor.eot?t=1490255243344');
    src: url('//at.alicdn.com/t/font_bomhhb0ckjz41jor.eot?t=1490255243344#iefix') format('embedded-opentype'),url('//at.alicdn.com/t/font_bomhhb0ckjz41jor.woff?t=1490255243344') format('woff'),url('//at.alicdn.com/t/font_bomhhb0ckjz41jor.ttf?t=1490255243344') format('truetype'),url('//at.alicdn.com/t/font_bomhhb0ckjz41jor.svg?t=1490255243344#mediconfont') format('svg')
}

.mediconfont {
    font-family: "mediconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.icon-apple-gray:before {
    content: "\e601"
}

.icon-android-gray:before {
    content: "\e602"
}

.icon-zhaomingyi:before {
    content: "\e606"
}

.icon-toutiao:before {
    content: "\e608"
}

.icon-tianshigu:before {
    content: "\e609"
}

.icon-xiaozu:before {
    content: "\e60a"
}

.icon-gaokeyan:before {
    content: "\e60e"
}

.icon-yaochuzhen:before {
    content: "\e60f"
}

.icon-jiewenzhen:before {
    content: "\e610"
}

.icon-kaichufang:before {
    content: "\e611"
}

h1,h2,h3,h4,h5,h5 {
    font-weight: 100
}

.med-history .med-carousel {
    width: 100%;
    height: 782px;
    background-color: #F7F9FD;
    display: flex;
    margin-top: 50px;
}

.med-history .med-carousel .carousel-left {
    padding-right: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 33%
}

.med-history .med-carousel .carousel-left .logo {
    width: 160px;
    height: 60px;
    background: url('@/assets/images/1.png') no-repeat;
    background-position: 50%
}

.med-history .med-carousel .carousel-middle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 15px
}

.med-history .med-carousel .carousel-middle .split-line {
    position: absolute;
    top: 0;
    left: 16px;
    width: 1px;
    height: 100%;
    z-index: 0;
    background-color: #e4e7eb
}

.med-history .med-carousel .carousel-middle .buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 15px;
    height: 100px;
    transform: translateX(8px);
    z-index: 1;
    background-color: #F7F9FD
}

.med-history .med-carousel .carousel-middle .buttons .top-arrow {
    height: 15px;
    width: 15px;
    background: url('../../../assets/newImages/top-arrow.png') no-repeat;
    background-position: top;
    background-size: 100% auto;
    transform: translateX(1px);
    cursor: pointer
}

.med-history .med-carousel .carousel-middle .buttons .circle {
    height: 30px;
    width: 30px;
    background: url('../../../assets/newImages/circle.png') no-repeat;
    background-position: 50%;
    background-size: 100% auto;
    transform: translateX(-7px)
}

.med-history .med-carousel .carousel-middle .buttons .bottom-arrow {
    height: 15px;
    width: 15px;
    background: url('../../../assets/newImages/bottom-arrow.png') no-repeat;
    background-position: bottom;
    background-size: 100% auto;
    transform: translateX(1px);
    cursor: pointer
}

.med-history .med-carousel .carousel-right {
    position: relative;
    height: 700px;
    overflow: hidden;
    flex: 1;
    margin: 41px 0
}

.med-history .med-carousel .carousel-right .carousel-items {
    position: relative;
    height: 100%;
    transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out
}

.med-history .med-carousel .carousel-right .carousel-items .item {
    display: flex;
    align-items: center;
    text-align: left;
    cursor: pointer;
    position: relative
}

.med-history .med-carousel .carousel-right .carousel-items .item .time {
    font-size: 16px;
    width: 200px;
    margin-left: 50px;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 400;
    color: #000000;
    display: inline-block
}

.med-history .med-carousel .carousel-right .carousel-items .item .text {
    width: 600px;
    font-size: 16px;
    display: inline-block;
    white-space: pre-wrap;
    font-family: PingFangSC-Thin,PingFang SC;
    color: #9ba3ad;
    font-weight: 100
}

.med-history .med-carousel .carousel-right .carousel-items .item-70 {
    height: 70px
}

.med-history .med-carousel .carousel-right .carousel-items .item-100 {
    height: 100px
}

.med-history .med-carousel .carousel-right .carousel-items .item-selected .time {
    color: #027AFF;
    font-size: 27px
}

.med-history .med-carousel .carousel-right .carousel-items .item-selected .text {
    width: 600px;
    color: #000000;
    font-size: 18px;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 400
}

.med-history .med-carousel .carousel-right .carousel-items .item-selected:before {
    content: "";
    display: block;
    position: absolute;
    height: 1px;
    opacity: .1;
    width: 100%;
    top: 0;
    background-image: linear-gradient(90deg, #748699 60%, rgba(64,78,96,0))
}

.med-history .med-carousel .carousel-right .carousel-items .item-selected:after {
    content: "";
    display: block;
    position: absolute;
    height: 1px;
    opacity: .1;
    bottom: 0;
    width: 100%;
    background-image: linear-gradient(90deg, #748699 60%, rgba(64,78,96,0))
}

</style>