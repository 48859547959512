<!--  -->
<template>
  <div class="one">
    <Nav></Nav>
    <div class="content">
      <div class="title">幽幽管取样前后注意事项</div>
      <div class="introduce">
        <span>文章来源:佚名 </span>
        <span>2021-04-12 14:05:54</span>
      </div>
      <div class="line"></div>
      <div class="msg">
        <p>取样前注意事项</p>
        <p>取新鲜粪便样本，成型或不成型粪便样本均可使用。</p>
        <p>样本采集时需要避开水和尿液，不可混有消毒剂及污水。</p>
        <p>撕开铝箔袋后，应在一小时内完成全部检测过程。</p>
        <p>产品有效期8个月，生产日期及使用期限见标签，已失效的产品不可使用。</p>
        <p>检测前至少需停用质子泵抑制剂PPI两周，抗生素和铋剂四周。</p>
        <p>取样中注意事项</p>
        <p>在检测过程中幽幽管不能倾倒，应始终保持其竖直放置。</p>
        <p>
          取样完成后，将取样棒插回试剂管中，摇晃试剂管10秒钟左右，使粪便样本充分混匀于稀释液中，掰下试剂管盖子上的白色限位块，保持试剂管竖立放置并压下盖子到最底。
        </p>
        <p>取样后注意事项</p>

        <div class="mask111"></div>
        <div class="more">余下内容为付费内容，需付费后继续阅读</div>
        <div class="buy" @click="toPay">立即支付</div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Nav from "@/components/nav/nav.vue";
import Bottom from "@/components/bottom/bottom.vue";
export default {
  components: { Nav, Bottom },

  Navdata() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    toPay() {
      this.$router.push({ name: "pay" });
    },
  },
};
</script>
<style lang='less' src="./index.less"  >
</style>