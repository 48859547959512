<template>
  <div>
    <div class="honor-background">
        <div class="honor-container">
            <div class="honor-line"></div>

            <div class="honor-item">
                <div class="honor-right-content">
                    <div class="honor-right-year"><span></span><p>2019年</p ></div>
                    <p class="honor-right-text">2019医药行业最具价值成长企业 — 新浪医药</p >
                    <p class="honor-right-text">2019年“互联网医疗之王” — 36Kr</p >
                    <p class="honor-right-text">2019最佳科技医疗创新企业 — 亿欧网</p >
                    <p class="honor-right-text">2019福布斯中国最具创新力企业榜 — 福布斯中国</p >
                    <p class="honor-right-text">2019数字医疗创新联盟首批联盟成员 — 腾讯</p >
                </div>
            </div>

            <div class="honor-item">
                <div class="honor-left-content">
                    <div class="honor-left-year"><span></span><p>2018年</p ></div>
                    <p class="honor-left-text">首批共享经济典型案例 — 国家发改委</p >
                    <p class="honor-left-text">2018年度创新企业、未来医疗100强  — 动脉网＆蛋壳研究院</p >
                    <p class="honor-left-text">2018年医疗领域“互联网医疗之王” — 36Kr</p >
                    <p class="honor-left-text">2018创业黑马TOP100 — 创业家&i黑马</p >
                    <p class="honor-left-text">2018年度智慧医疗企业 — 天府软件园</p >
                    <p class="honor-left-text">2018年互联网医院创新企业十强 — 亿欧网</p >
                </div>
            </div>

            <div class="honor-item">
                <div class="honor-right-content">
                    <div class="honor-right-year"><span></span><p>2017年</p ></div>
                    <p class="honor-right-text">2017年度中国最佳医疗服务领域投资案例TOP10 — 投中网</p >
                    <p class="honor-right-text">2017年度创新企业、未来医疗100强 — 动脉网＆蛋壳研究院</p >
                    <p class="honor-right-text">2017最 IN 数字医疗解决方案 — HC3i中国数字医疗网</p >
                    <p class="honor-right-text">2017年度中国生命健康产业独角兽企业 — 火石创造</p >
                    <p class="honor-right-text">2017医药产业助力创新10强 — 亿欧网</p >
                </div>
            </div>

            <div class="honor-item">
                <div class="honor-left-content">
                    <div class="honor-left-year"><span></span><p>2016年</p ></div>
                    <p class="honor-left-text">2016未来医疗100强 — 动脉网＆蛋壳研究院</p >
                    <p class="honor-left-text">2016年度最佳互联网健康医疗平台 — 艾媒咨询</p >
                    <p class="honor-left-text">2016德勤-华兴中国明日之星 — 德勤和华兴资本</p >
                </div>
            </div>

        </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  components: {

  },
  mounted() {

  },
  methods: {

  },
}

</script>
<style scoped lang='less'>
@charset "utf-8";* {
    box-sizing: border-box
}

body {
    font-family: 'PingFangSC-Thin','Microsoft YaHei light'
}

.honor-background {
    overflow: hidden;
    width: 100%;
    height: 1312px;
    background: #f7f9fd;
    margin-top: 50px;
}

.honor-container {
    position: relative;
    z-index: 1;
    width: 1150px;
    height: 1132px;
    margin: 80px auto 0
}

.honor-line {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    width: 1px;
    height: 1132px;
    margin: auto;
    background: #e5e7eb
}

.honor-item {
    position: relative;
    z-index: 2;
    overflow: hidden;
    width: 100%
}

.honor-right-content {
    float: right;
    overflow: hidden;
    width: 582px
}

.honor-right-year {
    font-size: 28px;
    line-height: 40px;
    overflow: hidden;
    width: 100%;
    height: 40px;
    margin: 30px 0 24px 0;
    color: #027aff
}

.honor-right-year span {
    display: block;
    float: left;
    width: 14px;
    height: 14px;
    margin-top: 13px;
    border-radius: 50%;
    background: #007aff
}

.honor-right-year p {
    float: left;
    margin-left: 24px
}

.honor-right-text {
    font-size: 20px;
    line-height: 32px;
    box-sizing: border-box;
    width: 100%;
    margin-top: 8px;
    padding-left: 39px;
    color: #1b1b1b
}

.honor-left-content {
    float: left;
    overflow: hidden;
    width: 582px
}

.honor-left-year {
    font-size: 28px;
    line-height: 40px;
    overflow: hidden;
    width: 100%;
    height: 40px;
    margin: 30px 0 24px 0;
    color: #027aff
}

.honor-left-year span {
    display: block;
    float: right;
    width: 14px;
    height: 14px;
    margin-top: 13px;
    border-radius: 50%;
    background: #007aff
}

.honor-left-year p {
    float: right;
    margin-right: 24px
}

.honor-left-text {
    font-size: 20px;
    line-height: 32px;
    box-sizing: border-box;
    width: 100%;
    margin-top: 8px;
    padding-right: 39px;
    text-align: right;
    color: #1b1b1b
}

</style>