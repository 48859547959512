<!--  -->
<template>
  <div class="">
    <ul>
      <li class="tip">商品分类</li>
      <li v-for="(item, index) in list" :key="index" @click="togo(item.id)">{{ item.title }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: '医疗器械',
          id: 'ylqx',
        },
        {
          title: '皮肤科药',
          id: 'pfky',
        },
        {
          title: '儿童用药',
          id: 'etyy',
        },

      ]
    };
  },
  mounted() {
  },
  methods: {
    togo(item) {
      if (this.$route.path == "/") {
        var serve = document.getElementById(item);
        const top = serve.offsetTop;
        window.scrollTo({top, behavior: "smooth"});
      } else {
        this.$router.push({path: "/"});
        setTimeout(() => {
          var serve = document.getElementById(item);
          const top = serve.offsetTop;
          window.scrollTo({top, behavior: "smooth"});
        }, 200);
      }
    },
  },
};
</script>
<style lang='less'>
// li {
//   width: 150px;
//   height: 81px;
//   line-height: 81px;
//   text-align: center;
//   color: rgba(255, 255, 255, 1);
//   font-size: 14px;
//   background-color: rgba(50, 50, 51, 1);
//   border-bottom: 1px solid rgba(255, 255, 255, 1);
//   cursor: pointer;
// }

// li:last-child {
//   border: none;
// }

// li:hover {
//   color: rgba(2, 90, 157, 1);
//   background-color: rgba(255, 255, 255, 1);
// }

.tip {
  background-color: rgba(2, 90, 157, 1);
  cursor: default;
}

.tip:hover {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(2, 90, 157, 1);
}
</style>
