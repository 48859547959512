<template>
    <div>
        <section class="banner-area">
            <p class="title">国内领先的专病专保科技平台</p>
            <p class="title-en">Leading specialized disease and protection technology platform in China.</p>
            <div class="line"></div>
            <p class="sub-title">看病无忧  保障有道</p>
        </section>
        <div  class="index-product">
            <div  class="product-inner">
                <div  class="inner-title"> 核心产品 </div>
                <div  class="inner-main">
                    <div  :class="`main-item ${item.class}`" v-for="(item,index) in productList" :key="index" @click="toPath(index)">
                        <a   class="flex" target="_blank">
                            <div  class="item-img"></div>
                            <div  class="item-info">
                                <div  class="info-title"> {{ item.title }} </div>
                                <ul  class="info-msg">
                                    <li v-for="(item1,index1) in item.child"> {{ item1 }} </li>
                                </ul>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- <section class="doctor-area">
            <div class="containter">
                <div class="left">
                    <div class="title-bg"></div>
                    <p class="title">医生工具</p>
                    <p class="des">为医生打造高效智能的慢病患者管理工具</p>
                    <p class="des">每位医生可管理至多2000位患者</p> 
                    <div class="doctor-count">
                        <div class="doctor-count-box" v-for="(item,index) in doctorAreaList" :key="index">
                            <img :src="item.img" />
                            <div>
                                <div class="count">
                                    {{ item.num }}
                                    <span></span>
                                </div>
                                <div class="name">{{ item.name }}</div>
                            </div>
                        </div>
                    </div>
                    <a class="doctor-more" @click="todoctor">
                        <span class="text">了解更多</span>
                        <div class="arrow"></div>
                    </a>
                </div>
                <div class="iphone"></div>
                <div class="qrcode-box">
                    <div class="qrcode">
                        <img 
                            alt="扫码下载医联APP" 
                            src="../../assets/newImages/doctor-app-qrcode.png" 
                        />
                    </div>
                    <div class="qrcode-name">
                        扫码下载医联APP
                    </div>
                </div>
            </div>
        </section> -->
        <!-- <section class="news-area">
            <div class="containter">
                <div class="title-bg"></div>
                <p class="title">公司新闻</p>
                <p class="des">了解医联 从这里开始</p>
                <ul class="news-list clearfix j-news-list" :class="index != 0 ? 'hover':''">
                    <li class="news-item" :class="index == index1 + 1 ? 'open':'close'" v-for="(item,index1) in newsList" :key="index1"  @mouseenter="handleMouseEnter(index1)" @mouseleave="handleMouseLeave(index)">                        
                        <a>                            
                            <div class="img-warp">
                                <div class="news-img" :style="`background-image: url(${item.img})`"></div>
                            </div>                            
                            <div class="news-detail">                                
                                <h1 class="news-title">{{ item.title }}</h1>                                
                                <div class="news-digest">{{ item.digest }}</div>                                
                                <div class="news-date">{{ item.date }}</div>                            
                            </div>                        
                        </a>                    
                    </li>
                </ul>
            </div>
        </section> -->
        <div data-v-490375a2="" class="index-public">
            <div data-v-490375a2="" class="public-inner">
                <div data-v-490375a2="" class="inner-title"> 度生科技公众号 </div>
                <div data-v-490375a2="" class="inner-main">
                    <div data-v-490375a2="" class="main-item main-doctor">
                        <div data-v-490375a2="" class="item-title"> 度生健康 </div>
                        <div data-v-490375a2="" class="item-msg"> 具有丰富资源及行业背景，致力于为中国百姓提供基本医保外的创新支付解决方案，降低老百姓医疗负担，提高服务可及性、便捷性、可负担性。 </div>
                        <img data-v-490375a2="" src="../../assets/newImages/qrcode_for_gh_93e3ef9f092f_258.jpg" class="item-img"></div>
                        <div data-v-490375a2="" class="main-item main-patient"><div data-v-490375a2="" class="item-title"> 度生全病程管理平台 </div>
                        <div data-v-490375a2="" class="item-msg"> 以患者为中心提供辅助医疗健康服务和科普宣教，伴随患者治疗全周期的管理，促进患者健康质量提升。 </div>
                        <img data-v-490375a2="" src="../../assets/newImages/gh_2d03daea74b9_258.jpg" class="item-img"></div>
                    </div>
                </div>
            </div>
    </div>
  </template>
  
  <script>
  import Nav from "@/components/nav/nav.vue";
  import Bottom from "@/components/bottom/bottom.vue";
  export default {
    inject: ["reload"],
    data() {
      return {
        index:0,
        newsList:[
            {
                img:'https://pub-med-panel.medlinker.com/FrR5U6WwGS1h-nkQ3H369h6sZvbq',
                title:'医联推出国内首款AI医生medGPT',
                digest:'作为中国领先的互联网医院，医联于今日正式发布国内首款大模型驱动的AI医生——medGPT，该产品目前...',
                date:'2023.4.28'
            },
            {
                img:'https://pub-med-panel.medlinker.com/Fiwe75t_BzYOVZ7DpwABifAJpTxq',
                title:'中国数字医疗科技创新企业图谱首次发布，医联成功入选',
                digest:'医联将继续秉承严肃医疗的战略和理念，为赋能医生、服务患者、行业发展释放自己的价值。',
                date:'2023.2.17'
            },
            {
                img:'https://pub-med-panel.medlinker.com/FkAf691wkfgoU3XHrhOE-Hn3a4pu',
                title:'医联荣誉墙又增加两个新成员！',
                digest:'医联在众多候选企业中屡次脱颖而出，体现了行业对医联医疗服务理念的认可，以及医疗服务能力的肯定。',
                date:'2023.1.16'
            }
        ],
        doctorAreaList:[
            {
                img:require('../../assets/newImages/doctor-certificate.png'),num:800000,name:'实名认证医生'
            },
            {
                img:require('../../assets/newImages/doctor-sign.png'),num:50000,name:'平台签约医生'
            },
            {
                img:require('../../assets/newImages/drug-store.png'),num:6000,name:'合作药企/药店'
            },
            {
                img:require('../../assets/newImages/drug-sku.png'),num:10000,name:'药品SKU'
            }
        ],
        productList:[
            {
                class:'main-case',title:'全病程管理',child:['以患者为中心提供辅助医疗健康服务和科普宣教','伴随患者治疗全周期的管理','促进患者健康质量提升']
            },
            {
                class:'main-ai',title:'专病专保-胃家保',child:['无幽之选是一款针对胃幽门螺杆菌的早筛并给予治疗保障的专病保险，实现了从疾病早筛到治疗的全流程闭环医疗服务。通过关注度生健康公众号，可激活保险保障，享受对应的幽门螺杆菌阳性理赔、7折购药、互联网医院全科问诊等服务权益']
            },
            {
                class:'main-pasi',title:'专病专保-软骨新生',child:['度生平台特别推出“关节软骨新生”患者福利项目为符合项目条件的软骨损伤患者提供保障。']
            },
            {
                class:'main-case',title:'专病专保-保列久安',child:['患者通过入组持续合理用药，实现对良性前列腺增生更好的治疗;通过使用福利权益在指定机构专项检查，报销检查费用，为用药后的复查提供福利保障。']
            },
            {
                class:'main-scorad',title:'专病专保-爱巢保',child:['泰坤堂是由泰坤堂中医研究院、上海市中医药学会、中国科学院上海中药现代化研究中心等联倡创办的医教研一体的我国首家现代中医女科医疗连锁集团.']
            },
            {
                class:'main-bcms',title:'云药房商城',child:['提供全模块积木式建设方案，灵活多样，客户可以根据自身的需求自由定制。致力打造移动时代覆盖最广、效率最高的互联网医院一站式云服务平台。']
            },
        ]
      };
    },
    components: {
      Nav,
      Bottom,
    },
    mounted() {
    //   var dislist = document.querySelectorAll(".diease");
    //   dislist.forEach((item) => {
    //     item.style.display = "none";
    //   });
    },
    methods: {
        toPath(index){
            if(index == 5){
                return
            }
            this.$router.push({ 
                path: 'text',
                query: {'index': index + 1}
            });
        },
        todoctor() {
            this.$router.push({ path: "/doctor" });
        },
        handleMouseEnter(e){
            this.index = e + 1
        },
        handleMouseLeave(e){
            this.index = 0
        },
      toDetails(index, index1) {
        this.index = index;
        this.$router.push({
          name: "details",
          query: { d: JSON.stringify(this.list[index1].goodslist[index]) },
        });
      },
      toSearch() {
        this.$router.push({
          name: "search",
          params: { details: this.goodslist },
        });
      },
      choose(index) {
        this.active = index;
      },
      bannerleave() {
        var dislist = document.querySelectorAll(".diease");
        dislist.forEach((item) => {
          item.style.display = "none";
          this.active = 10;
        });
      },
      refresh() {
        location.reload();
      },
    },
  };
  </script>
  <style scoped lang='less' src="./newHome.less"></style>
  