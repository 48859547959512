<template>
  <div>
    <div class="bottom">
      <div class="bottom_cen">
        <div class="bottom_text">
          <div class="bottom_text_1">
            公司地址：上海市徐汇区钦州路100号2号楼102室
          </div>
          <div class="bottom_text_1">
            度生信息科技（上海）有限公司 &nbsp&nbsp&nbsp
            <a
              href="https://beian.miit.gov.cn/#/Integrated/index"
              style="color: #a1a1c2"
              >网站备案：沪ICP备2022010923号-1</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang='less' >
.bottom {
  min-width: 1200px;
  height: 144px;
  background: #25253f;
  display: flex;
  justify-content: center;
  align-items: center;

  .bottom_cen {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .bottom_text {
      cursor: default;
      height: 44px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .bottom_text_1 {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #a1a1c2;
        line-height: 22px;
      }
    }
  }
}
</style>
