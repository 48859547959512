<template>
  <div>
    <div data-v-d5eca55a="" class="product-bg"></div>
    <!-- <div class="main-content">
      <div class="banner"></div>
    </div> -->
    <div class="about-content">
      <div class="main">
        <div class="title">
          <p>公司业务</p >
        </div>
        <div class="content-main">
          <div class="content-main-left">
            <p>度生科技（软银资本、国控华药等知名资方），具有丰富资源及行业背景，致力于为中国百姓提供基本医保外的创新支付解决方案，降低老百姓医疗负担，提高服务可及性、便捷性、可负担性。目前建立了面向全国的完整的药品交付网络，构建线上线下结合数字医疗服务体系，打造以数字支付为核心的平台，链接患者、医生、药房、保险公司和药企，提升患者院前院后的疾病管理和健康管理水平。</p>
          </div>
          <span></span>
        </div>
        <div class="ding-list pc-container">
          <div class="list">
            <div class="one">
              <div class="title1">使命</div>
              <div class="line">看病无忧、保障有道</div>
            </div>
            <div class="one">
              <div class="title1">愿景</div>
              <div class="line">国内领先的专病专保科技平台</div>
            </div>
            <div class="one">
              <div class="title1">价值观</div>
              <div class="line">用户至上、目标导向<br>责任担当、守正创新</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="culture-content">
      <div class="culture-wrapper">
        <div class="culture-title-box">
          <div class="title">
            <p>企业价值观</p >
          </div>
        </div>
        <div class="culture-main">
          <span></span>
          <p>用户至上、目标导向、责任担当、守正创新</p >
        </div>
      </div>
    </div> -->

    <div class="team-content">
      <div class="ceo-introduction">
        <div class="title">
          <p>管理团队</p >
        </div>
        <div class="ceo-content">
          <div class="content-wrapper">
            <p class="ceo">杨建纯</p >
            <p class="ceo">度生科技创始人&CEO，西安电子科技大学MBA兼职研究生导师</p >
            <div class="space"></div>
            <p class="content">先后于上海复旦医管、微医、百度健康等担任高层职位，在医疗数字化、保险科技、医药营销等逾15年跨界融合经验。</p>
            <p class="content" style="margin: 15px 0;">主要学术兼职：</p>
            <p class="content">中国卫生信息与健康医疗大数据学会互联网医院标准化委员会副主任委员</p>
            <p class="content">中国医学装备人工智能联盟检验医学委员会副主任委员</p>
            <p class="content">中国医药教育协会数字疗法工作委员会常委</p>
            <p class="content">中国老年学学会老年医学委员会智慧医疗与养老照护专家委员会委员</p >
            <p class="content">2022-2023年度上海市徐汇区光启人才</p >
          </div>
          <div class="ceo-pic"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  mounted() {

  },
  methods: {

  },
}

</script>
<style scoped lang='less'>
@charset "utf-8";* {
    box-sizing: border-box
}

body {
    font: '14px/18px PingFang SC, PingFangSC-Semibold, "Microsoft Yahei", Tahoma, Helvetica, Arial, Verdana, "\5b8b\4f53", sans-serif'
}
.pc-container {
  width: 1200px;
  margin: 0px auto;
  .list {
      display: flex;
      flex-wrap: wrap;
       .one {
        width: 33.33%;
        border-left: solid 1px #ccc;
        padding: 0 18px;
        text-align: center;
          .title1 {
              margin-bottom: 20px;
              font-size: 24px;
              color: #333;
          }
          .line {
              font-size: 18px;
              color: #777;
              line-height: 25px;
          }
      }
      .one:nth-child(1){
        border: none;
      }
  }
}

.product-bg[data-v-d5eca55a] {
    width: 100%;
    height: 352px;
    padding-top: 1px;
    text-align: center;
    background-image: url("@/assets/newImages/banner111.png");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: auto 100%
}

.main-content {
    width: 1200px;
    margin: 0 auto
}

.banner {
    width: 100%;
    height: 540px;
    background: url('../../../assets/newImages/bg@2x.png') no-repeat;
    background-size: contain
}

.about-content {
    padding: 80px 0 64px;
    background-color: #f7f9fd
}

.about-content .main {
    width: 1200px;
    margin: 0 auto
}

.about-content .main .title {
    position: relative;
    width: 100%;
    height: 45px;
    margin-bottom: 48px;
    background: url('../../../assets/newImages/title-pic@2x.png') no-repeat;
    background-size: contain
}

.about-content .main .title p {
    font-size: 44px;
    font-weight: 300;
    position: absolute;
    bottom: 4px;
    color: #202020
}

.about-content .main .content-main {
    display: flex;
    flex-direction: row;
    margin-bottom: 50px
}

.about-content .main .content-main .content-main-left {
    width: 50%;
    margin: 0 20px 0 20px
}

.about-content .main .content-main .content-main-left p {
    font-family: PingFangSC-Thin;
    font-size: 20px;
    line-height: 32px;
    text-align: justify;
    letter-spacing: 0;
    opacity: .7;
    color: #000
}

.about-content .main .content-main span {
    display: block;
    width: 336px;
    height: 327px;
    margin-left: 81px;
    background: url('../../../assets/newImages/culture-img02.png') no-repeat;
    background-size: contain
}

.culture-content {
    padding: 80px 0 64px;
    background-color: #fff
}

.culture-wrapper {
    overflow: hidden;
    width: 1200px;
    margin: 0 auto
}

.culture-wrapper .culture-title-box {
    overflow: hidden;
    width: 100%
}

.culture-wrapper .title {
    position: relative;
    float: right;
    width: 352px;
    height: 70px;
    background: url('../../../assets/newImages/title-pic2@2x.png') no-repeat right bottom;
    background-size: contain
}

.culture-wrapper .title p {
    font-size: 44px;
    font-weight: 300;
    position: absolute;
    right: 11px;
    bottom: 4px;
    color: #202020
}

.culture-wrapper .culture-main {
    margin-top: 50px;
    overflow: hidden
}

.culture-wrapper .culture-main p {
    font-family: PingFangSC-Thin;
    font-size: 20px;
    line-height: 32px;
    float: right;
    width: 50%;
    margin-top: 114px;
    text-align: right;
    letter-spacing: 0;
    opacity: .7;
    color: #000
}

.culture-wrapper .culture-main span {
    display: block;
    float: left;
    margin-left: 30px;
    width: 336px;
    height: 327px;
    background: url('../../../assets/newImages/culture.png') no-repeat;
    background-size: contain
}

.team-content {
    padding: 80px 0 64px;
    // background-color: #f7f9fd
}

.ceo-introduction {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0 98px 0
}

.ceo-introduction .title {
    position: relative;
    width: 100%;
    height: 45px;
    margin-bottom: 48px;
    background: url('../../../assets/newImages/title-pic@2x.png') no-repeat;
    background-size: contain
}

.ceo-introduction .title p {
    font-size: 44px;
    font-weight: 300;
    position: absolute;
    bottom: 4px;
    color: #202020
}

.ceo-introduction .ceo-content {
    display: flex;
    flex-direction: row
}

.ceo-introduction .ceo-content .content-wrapper {
    width: 60%;
    margin-right: 110px
}

.ceo-introduction .ceo-content .content-wrapper .ceo {
    font-family: PingFangSC-Thin;
    font-size: 28px;
    font-weight: normal;
    letter-spacing: 1.55px;
    color: #000
}

.ceo-introduction .ceo-content .content-wrapper .space {
    margin-top: 20px
}

.ceo-introduction .ceo-content .content-wrapper .content {
    font-family: PingFangSC-Thin;
    font-size: 20px;
    line-height: 32px;
    text-align: justify;
    letter-spacing: 0;
    opacity: .7;
    color: #000
}

.ceo-introduction .ceo-content .ceo-pic {
    width: 40%;
    height: 200px;
    background: url('../../../assets/newImages/sm.png') no-repeat;
    background-size: contain
}

</style>