import NET from './net';

import {
    HOMEMODULE,
} from './urls.js'
import Vue from 'vue';

//post请求
function fetchPost(url, data, headers = {
    "Content-Type": 'application/json' //默认
}) {
    return new Promise((resolve, reject) => {
        //QS.stringify(params)
        NET.post(url, data, headers).then(res => {
            // console.log(res);
            resolve(res.result);
        }).catch(err => {
            //console.log(err);
            //显示接口报错
            //reject(err.data)
        })
    });
}

//get请求
function postByFetch(url, config) {
    return fetch(process.env.VUE_APP_API_URL + url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'omit',
        headers: {
            "Content-Type": "application/json",
            // "X-Access-Token": Vue.ls.get(ACCESS_TOKEN)
        },
        ...config,
    }).then(res => {
        return res.json()
    }).then(data => {
        return Promise.resolve(data.result)
    }).catch(err => {
        console.log(err)
    })
}




const HOME = {

    getInfortype(data) {
        return fetchPost(HOMEMODULE.getInfortype, data)
    },
    getcategorylist(data) {
        return fetchPost(HOMEMODULE.getcategorylist, data)
    },
    queryInfoDetailById(data) {
        return fetchPost(HOMEMODULE.queryInfoDetailById, data)
    }
}






let a = 1

export {
    HOME,
}
