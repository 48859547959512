<template>
    <div>
      <div class="drug-store">
        <section class="banner">
            <div class="banner-text">
                <p>精选优质药品，打造医联云药房，提升药品可及性</p>
                <p>6000家合作药企/药店，12000+药品SKU</p>
            </div>
        </section>
        <section class="product-show"></section>
        <section class="partner">
            <p class="title">部分合作伙伴展示</p>
            <div class="img-content">
                <div class="img-title">
                    <img src="@/assets/newImages/drugStore/infected.png" alt="感染科">
                </div>
                <div class="img-item">
                    <img class="left-item" src="@/assets/newImages/drugStore/gilead.png" alt="gilead">
                    <img src="@/assets/newImages/drugStore/msd.png" alt="msd">
                </div>
            </div>
            <div class="img-content bg">
                <div class="img-title">
                    <img src="@/assets/newImages/drugStore/endocrine.png" alt="内分泌科">
                </div>
                <div class="img-item">
                    <img class="left-item" src="@/assets/newImages/drugStore/janssen.png" alt="janssen">
                    <img src="@/assets/newImages/drugStore/novo.png" alt="novo">
                    <img class="left-item" src="@/assets/newImages/drugStore/takeda.png" alt="takeda">
                    <img src="@/assets/newImages/drugStore/merck.png" alt="merck">
                    <img class="left-item" src="@/assets/newImages/drugStore/astrazeneca.png" alt="astrazeneca">
                    <img src="@/assets/newImages/drugStore/lieey.png" alt="lieey">
                </div>
            </div>
            <div class="img-content">
                <div class="img-title">
                    <img src="@/assets/newImages/drugStore/cardcere.png" alt="心脑血管科">
                </div>
                <div class="img-item">
                    <img class="left-item" src="@/assets/newImages/drugStore/pfizer.png" alt="pfizer">
                    <img src="@/assets/newImages/drugStore/abbott.png" alt="abbott">
                    <img class="left-item" src="@/assets/newImages/drugStore/novartis.png" alt="novartis">
                    <img src="@/assets/newImages/drugStore/shp.png" alt="shp">
                    <img class="left-item" src="@/assets/newImages/drugStore/med.png" alt="med">
                    <img src="@/assets/newImages/drugStore/bay.png" alt="bay">
                    <img class="left-item" src="@/assets/newImages/drugStore/snf.png" alt="snf">
                    <img />
                </div>
            </div>
            <div class="img-content bg">
                <div class="img-title">
                    <img src="@/assets/newImages/drugStore/reas.png" alt="呼吸哮喘">
                </div>
                <div class="img-item">
                    <img class="left-item" src="@/assets/newImages/drugStore/gsk.png" alt="gsk">
                    <img src="@/assets/newImages/drugStore/boehringer.png" alt="boehringer">
                </div>
            </div>
            <div class="img-content">
                <div class="img-title">
                    <img src="@/assets/newImages/drugStore/tumour.png" alt="肿瘤科">
                </div>
                <div class="img-item">
                    <img class="left-item" src="@/assets/newImages/drugStore/roche.png" alt="roche">
                    <img src="@/assets/newImages/drugStore/simcere.png" alt="simcere">
                    <img class="left-item" src="@/assets/newImages/drugStore/phama.png" alt="phama">
                    <img />
                </div>
            </div>
        </section>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data () {
      return {
  
      }
    },
    components: {
  
    },
    mounted() {
  
    },
    methods: {
  
    },
  }
  
  </script>
  <style scoped lang='less'>
@charset "utf-8";* {
    box-sizing: border-box
}

body {
    font-family: 'PingFangSC-Thin','Microsoft YaHei light'
}

@font-face {
    font-family: "mediconfont";
    src: url('//at.alicdn.com/t/font_bomhhb0ckjz41jor.eot?t=1490255243344');
    src: url('//at.alicdn.com/t/font_bomhhb0ckjz41jor.eot?t=1490255243344#iefix') format('embedded-opentype'),url('//at.alicdn.com/t/font_bomhhb0ckjz41jor.woff?t=1490255243344') format('woff'),url('//at.alicdn.com/t/font_bomhhb0ckjz41jor.ttf?t=1490255243344') format('truetype'),url('//at.alicdn.com/t/font_bomhhb0ckjz41jor.svg?t=1490255243344#mediconfont') format('svg')
}

.mediconfont {
    font-family: "mediconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.icon-apple-gray:before {
    content: "\e601"
}

.icon-android-gray:before {
    content: "\e602"
}

.icon-zhaomingyi:before {
    content: "\e606"
}

.icon-toutiao:before {
    content: "\e608"
}

.icon-tianshigu:before {
    content: "\e609"
}

.icon-xiaozu:before {
    content: "\e60a"
}

.icon-gaokeyan:before {
    content: "\e60e"
}

.icon-yaochuzhen:before {
    content: "\e60f"
}

.icon-jiewenzhen:before {
    content: "\e610"
}

.icon-kaichufang:before {
    content: "\e611"
}

h1,h2,h3,h4,h5,h5 {
    font-weight: 100
}

.drug-store .banner {
    width: 100%;
    min-width: 1200px;
    height: 730px;
    background: url('@/assets/newImages/drugStore/banner.png') no-repeat;
    background-size: cover;
    background-position: center center
}

.drug-store .banner .banner-text {
    padding-top: 381px
}

.drug-store .banner .banner-text p {
    text-align: center;
    font-size: 32px;
    font-family: PingFangSC-Light,PingFang SC;
    font-weight: 300;
    line-height: 45px;
    color: #ffffff;
    opacity: .7
}

.drug-store .product-show {
    width: 100%;
    min-width: 1200px;
    height: 1192px;
    background: url('@/assets/newImages/drugStore/productShow.png') no-repeat;
    background-size: cover;
    background-position: center center
}

.drug-store .partner {
    min-width: 1200px;
    padding: 96px 0 100px 0
}

.drug-store .partner .title {
    margin-bottom: 56px;
    text-align: center;
    font-size: 44px;
    font-family: PingFangSC-Light,PingFang SC;
    font-weight: 300;
    color: #202020;
    position: relative
}

.drug-store .partner .title:after {
    position: absolute;
    content: "";
    background: #007AFF;
    width: 68px;
    height: 4px;
    bottom: -30px;
    right: calc(50% - 34px)
}

.drug-store .partner .img-content {
    text-align: center;
    padding: 34px 0 4px 0
}

.drug-store .partner .img-content .img-title {
    display: inline-block;
    vertical-align: top;
    margin-right: 68px
}

.drug-store .partner .img-content .img-title img {
    margin-top: 20px;
    width: 154px;
    height: 100%
}

.drug-store .partner .img-content .img-item {
    display: inline-block;
    width: 750px
}

.drug-store .partner .img-content .img-item img {
    width: 300px;
    height: 100%;
    margin-bottom: 30px;
    border: none
}

.drug-store .partner .img-content .img-item .left-item {
    margin-right: 56px
}

.drug-store .partner .bg {
    background-color: #F7F9FD
}

  
  </style>