<template>
  <div class="help">
    <Nav></Nav>
    <div class="banner">
      <!-- <img src="../../assets/images/hmb.png" alt=""> -->
    </div>
    <div class="help_cen">
      <div class="imgbox">
        <img src="../../assets/images/404.png" alt="" />
      </div>
      <div class="text">2022年沪惠保投保通道关闭</div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Nav from "@/components/nav/nav.vue";
import Bottom from "@/components/bottom/bottom.vue";
export default {
  data() {
    return {};
  },
  components: {
    Nav,
    Bottom,
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped lang='less' src="./index.less">
</style>