<!--  -->
<template>
  <div class="">
    <Nav></Nav>
    <div class="Search">
      <div class="search">
        <div class="search_cen">
          <div class="search_2">
            <input
              type="text"
              name=""
              id=""
              placeholder="请输入商品名称"
              class="search_2_1"
              ref="input"
            />
            <div class="search_2_2" @click="Search()">搜索</div>
          </div>
        </div>
      </div>
      <div class="searchbox">
        <div class="searchbox_cen">
          <div class="searchbox_1">商品列表</div>
          <div class="searchbox_3">
            <div class="searchbox_3_1">
              <div class="text">综合</div>
            </div>
            <div class="searchbox_3_1" @click="sale" ref="sale">
              <div class="text" ref="sale1">销量</div>
              <div class="icon">
                <img src="../../assets/images/bottom.png" alt="" ref="icon1" />
              </div>
            </div>
            <div class="searchbox_3_1" @click="price" ref="price">
              <div class="text" ref="price1">价格</div>
              <div class="icon">
                <img src="../../assets/images/bottom.png" alt="" ref="icon2" />
              </div>
            </div>
          </div>
          <div class="searchbox_4">共{{ goodslist.length }}件商品</div>
        </div>
      </div>
      <div class="goods">
        <div class="goods_cen">
          <div class="goodslist">
            <div
              class="goodsitem"
              v-for="(item, index) in goodslist"
              :key="index"
              @click="toDetails(index)"
            >
              <div class="goodsimg">
                <img :src="item.src" alt="" />
              </div>
              <div class="goodsmsg">
                <div class="goodsmsg_1">{{ item.name }}</div>
                <div class="goodsmsg_2">
                  <span class="span1">¥{{ item.xianjia }}</span>
                  <span class="span2">¥{{ item.yuanjia }}</span>
                </div>
                <div class="goodsmsg_3">
                  <div class="imgs"></div>
                  <span class="span3">{{ item.changjia }}</span>
                </div>
              </div>
            </div>
          </div>
          <van-pagination v-model="currentPage" :page-count="1" mode="simple" />
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Nav from "@/components/nav/nav.vue";
import Bottom from "@/components/bottom/bottom.vue";
export default {
  inject: ["reload"],
  components: { Nav, Bottom },
  data() {
    return {
      currentPage: 1,
      sort: true,
      goodslist: [
        {
          src: require("../../assets/images/yao2.png"),
          name: "幽幽管",
          yuanjia: "169",
          xianjia: "149",
          guige: "1人/份",
          changjia: "九和堂药房",
          qiye: "杭州诺辉健康科技有限公司",
          pinpai: "诺辉健康",
          xinghao: "国械注准20213401126",
          zhuce: "国械注准20213401126",
          detail_1: require("../../assets/images/detail1-1.png"),
          detail_2: require("../../assets/images/detail1-2.png"),
          detail_3: require("../../assets/images/detail1-3.png"),
          youtu1: require("../../assets/images/youtu1.png"),
          youtu1_1: "噗噗管",
          youtu1_2: "¥99.00",
          youtu2: require("../../assets/images/youtu2.png"),
          youtu2_1: "胃健康管理服务",
          youtu2_2: "¥6999.00",
          sale: 100,
        },
        {
          src: require("../../assets/images/yao1.png"),
          name: "噗噗管",
          yuanjia: "119",
          xianjia: "99",
          guige: "1人/份",
          qiye: "杭州诺辉健康科技有限公司",
          changjia: "诺辉健康旗舰店",
          pinpai: "诺辉健康",
          xinghao: "浙械注准20182400230",
          zhuce: "浙械注准20182400230",
          detail_1: require("../../assets/images/detail02-1.png"),
          detail_2: require("../../assets/images/detail02-2.png"),
          detail_3: require("../../assets/images/detail2-3.png"),
          youtu1: require("../../assets/images/putu1.png"),
          youtu1_1: "幽幽管",
          youtu1_2: "¥149.00",
          youtu2: require("../../assets/images/youtu2.png"),
          youtu2_1: "胃健康管理服务",
          youtu2_2: "¥6999.00",
          sale: 200,
        },
        {
          src: require("../../assets/images/wei11.png"),
          name: "胃健康管理服务",
          yuanjia: "269",
          xianjia: "199",
          changjia: "平安健康",
          qiye: " - ",
          guige: "1人/份",
          pinpai: "胃健康管理服务",
          xinghao: " - ",
          zhuce: " - ",
          detail_1: require("../../assets/images/detail4-1.png"),
          detail_2: require("../../assets/images/detail4-2.png"),
          detail_3: require("../../assets/images/detail4-3.png"),
          youtu1: require("../../assets/images/putu1.png"),
          youtu1_1: "幽幽管",
          youtu1_2: "¥149.00",
          youtu2: require("../../assets/images/youtu1.png"),
          youtu2_1: "噗噗管",
          youtu2_2: "¥99.00",
          sale: 320,
        },
      ],
      top: require("../../assets/images/top.png"),
      bottom: require("../../assets/images/bottom.png"),
    };
  },
  mounted() {},
  methods: {
    toDetails(index) {
      this.$router.push({
        name: "details",
        params: { details: this.goodslist, index: index },
      });
    },

    sortList(array, key) {
      return array.sort((a, b) => {
        var x = a[key];
        var y = b[key];
        return y < x ? -1 : y > x ? 1 : 0; //如果要从大到小,把x,y互换就可以了
      });
    },
    orderList(array, key) {
      return array.sort((a, b) => {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0; //如果要从大到小,把x,y互换就可以了
      });
    },

    price() {
      this.sort = !this.sort;
      if (this.sort) {
        this.sortList(this.goodslist, "xianjia");
        this.$refs.icon2.src = this.bottom;
        this.$refs.price.style.background = "#FFFFFF";
        this.$refs.price1.style.color = "#323233";
      } else {
        this.orderList(this.goodslist, "xianjia");
        this.$refs.icon2.src = this.top;
        this.$refs.price.style.background = "#2646FF";
        this.$refs.price1.style.color = "#FFFFFF";
      }
    },
    sale(e) {
      this.sort = !this.sort;
      if (this.sort) {
        this.sortList(this.goodslist, "sale");
        this.$refs.icon1.src = this.bottom;
        this.$refs.sale.style.background = "#FFFFFF";
        this.$refs.sale1.style.color = "#323233";
      } else {
        this.orderList(this.goodslist, "sale");
        this.$refs.icon1.src = this.top;
        this.$refs.sale.style.background = "#2646FF";
        this.$refs.sale1.style.color = "#FFFFFF";
      }
    },
    Search() {
      this.goodslist = this.goodslist;
      let _search = this.$refs.input.value.toLowerCase();
      let newListData = [];
      if (_search) {
        this.goodslist.filter((item) => {
          if (item.name.toLowerCase().indexOf(_search) !== -1) {
            newListData.push(item);
            this.goodslist = newListData;
          }
          if (item.name.toLowerCase().indexOf(_search) == -1) {
            // this.$toast("请输入正确的药名");
          }
        });
      } else if (!_search) {
        // this.$toast("请输入正确的药名");
        this.goodslist = this.goodslist;
      }
    },
  },
};
</script>
<style lang='less' src='./index.less'>
</style>