<template>
    <div class="disease">
        <Nav></Nav>
        <div class="banner">
            <img src="../../assets/images/dis1.png" alt="">
        </div>

        <div class="describe">
            <div class="describe_cen">
                <div class="describe_1">关节软骨新生</div>
                <div class="describe_2">&emsp;&emsp;注射用软骨再生胶原蛋白填充材料ChondroFiller
                    liquid（以下简称CF）是以修复损伤的软骨为核心治疗目标的国际新型产品。<br>
                    &emsp;&emsp;度生平台特别推出“关节软骨新生”患者福利项目为符合项目条件的软骨损伤患者提供保障。<br>
                    &emsp;&emsp;患者注册成为度生会员后，进入“关节软骨新生”项目，提交患者本人的相关信息及证明材料，自愿申请入组福利项目经预审后，完成相应流程和审核通过，即可获得相应的会员权益，即在项目指定医院接受CF耗材及软骨修复医疗服务，经判定对治疗效果未达到预期的患者，提供最高不超过120000元的福利金，同时可根据情况提供6000元的自费补偿；并根据会员实际需要提供康复指导、复诊提醒，客服全程指导的一站式福利服务。<br>
                    &emsp;&emsp;会员领取福利券即视自愿同意并参加以度生平台作为投保人的保险，成功通过入组核对后的患者在福利权益保障期间，在提供真实有效且完成材料提交的前提并经审核通过的，即可享有相应的福利金。
                </div>
            </div>
        </div>
        <div class="welfare">
            <div class="welfare_cen auto">
                <div class="welfare_text">
                    三大福利
                </div>
                <div class="welfare_list">
                    <div class="welfare_item">
                        <img src="../../assets/images/dis2.png" alt="">
                        <div class="welfare_item_text">康复保障 自费补偿</div>
                    </div>
                    <div class="welfare_item">
                        <img src="../../assets/images/dis3.png" alt="">
                        <div class="welfare_item_text">手术安排 材料保障</div>
                    </div>
                    <div class="welfare_item">
                        <img src="../../assets/images/dis4.png" alt="">
                        <div class="welfare_item_text">健康服务 康复指导</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="project">
            <div class="project_cen auto">
                <div class="project_text">
                    项目入组
                </div>
                <div class="project_1">
                    <div class="project_1_1">
                        <div class="text">
                            操作流程
                        </div>
                        <div class="item">
                            <div class="item_1">
                                <div class="item_1_1">
                                    <img src="../../assets/images/circle.png" alt="">
                                </div>
                                <div class="item_1_2"></div>
                                <div class="item_1_3">
                                    <img src="../../assets/images/circle.png" alt="">
                                </div>
                                <div class="item_1_4"></div>
                                <div class="item_1_5">
                                    <img src="../../assets/images/circle.png" alt="">
                                </div>
                                <div class="item_1_6"></div>
                                <div class="item_1_7">
                                    <img src="../../assets/images/circle.png" alt="">
                                </div>
                            </div>
                            <div class="item_2">
                                <div class="item_2_1">关注公众号，找到项目入口</div>
                                <div class="item_2_2">根据注册页面提示，注册登录</div>
                                <div class="item_2_3">在线提交预入组材料，并签署《用户协议》《服务协议》等协议，等待平台审核</div>
                                <div class="item_2_4">预入组材料经项目平台核对通过后，专员联系患者，支付相应费用，即为患者成功入组</div>
                                <div class="item_2_5">（以上材料在2个工作日内核对完毕）</div>
                            </div>
                        </div>
                    </div>
                    <div class="project_1_2">
                        <div class="project_1_2_1">预入组申请材料</div>
                        <div class="project_1_2_2">
                            <div class="serial">1</div>患者年龄在24-65周岁（含）
                        </div>
                        <div class="project_1_2_3">
                            <div class="serial">2</div>身份证正反面清晰照片
                        </div>
                        <div class="project_1_2_4" margin>
                            <div class="serial">3</div>指定医院出具的患处3.0T的MRI影像
                        </div>
                        <div class="project_1_2_5">*具体要求（准入标准和适应症）
                        </div>
                        <div class="project_1_2_6">
                            1.局灶性软骨损伤，单个或以上部位的软骨损伤，周围有正常的软骨覆盖&nbsp;
                            2.除损伤部位外，关节面完整（允许损伤为Outerbridge分级II级共存 ）&nbsp;&nbsp;
                            3.相对完整的半月板（最大切除部分不超过半月板体积的1/2）&emsp;&emsp;&emsp;&emsp;&emsp;
                            4.软骨损伤Outerbridge分级为 III 或
                            IV级&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            5.缺损面积≤6 cm2
                            6.如果关节软骨损伤同时存在：韧带损伤、半月板损伤、下肢轴线异常等需要手术的情况，可以同时进行CF手术。</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="apply">
            <div class="apply_cen auto">
                <div class="apply_text">福利申请</div>
                <div class="apply_1">
                    <div class="apply_1_1">
                        <div class="apply_1_1_text">福利金申请流程</div>
                        <div class="item">
                            <div class="item_1">
                                <div class="item_1_1">
                                    <img src="../../assets/images/circle.png" alt="">

                                </div>
                                <div class="item_1_2"></div>
                                <div class="item_1_3">
                                    <img src="../../assets/images/circle.png" alt="">

                                </div>
                                <div class="item_1_4"></div>
                                <div class="item_1_5">
                                    <img src="../../assets/images/circle.png" alt="">

                                </div>
                            </div>
                            <div class="item_2">
                                <div class="item_2_1">关注公众号，找到项目入口</div>
                                <div class="item_2_2">点击【我的权益】，在康复保障页【申请福利金】</div>
                                <div class="item_2_3">根据页面要求上传对应的资料，审核通过后相应的福利金会转账到用户指定的银行账户。</div>
                            </div>
                        </div>
                    </div>
                    <div class="apply_1_2">
                        <div class="apply_1_2_1">福利金申请材料</div>
                        <div class="apply_1_2_2">
                            <div class="flex" style="margin-right: 77px;">
                                <div class="serial">1</div>就医和服务发票
                            </div>
                            <div class="flex">
                                <div class="serial">2</div>6个月和11个月的患处MRI
                            </div>
                        </div>
                        <div class="apply_1_2_3">
                            <div class="flex" style="margin-right: 33px;">
                                <div class=" serial">3</div>6和11个月的康复评估
                            </div>
                            <div class="flex">
                                <div class="serial">4</div>负重训练及CPM及对应的医疗发票
                            </div>
                        </div>
                        <div class="apply_1_2_5">*具体要求（准入标准和适应症） </div>
                        <div class="apply_1_2_6">
                            <div class="serial">1</div>
                            <div class="text">保障期内，用户使用CF并按要求完成康复过程，经医学鉴定不符合软骨修复标准</div>
                        </div>
                        <div class="apply_1_2_7">
                            <div class="serial">2</div>再次治疗费用及治疗期补助金（限额）
                        </div>
                        <div class="apply_1_2_8">
                            <div class="serial">3</div>保障期：治疗开始-判定康复
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Bottom></Bottom>
    </div>
</template>

<script>
import Nav from '@/components/nav/nav.vue';
import Bottom from '@/components/bottom/bottom.vue';
export default {
    data() {
        return {

        }
    },
    components: {
        Nav,
        Bottom
    },
    mounted() {

    },
    methods: {

    },
}

</script>
<style scoped lang='less' src="./index.less">

</style>