<template>
  <div>
    <!-- <Nav></Nav> -->
    <div class="wrapper">
      <!-- <div class="search"></div>
      <div class="banner">
        <div class="banner_cen">
          <div class="banner_2"></div>
        </div>
      </div> -->
      <div class="goods">
        <div class="goods_cen" :id="item1.id" v-for="(item1, index1) in list" :key="index1">
          <div class="goods_text">{{ item1.title }}</div>
          <div class="goodslist">
            <div class="goodsitem" v-for="(item, index) in item1.goodslist" :key="index"
              @click="toDetails(index, index1)">
              <div class="goodsimg">
                <!-- <img :src="item.src" alt="" /> -->
                <van-image width="208" height="208" :src="item.src" />
              </div>
              <div class="goodsmsg">
                <div class="goodsmsg_1 line1">{{ item.name }}</div>
                <div class="goodsmsg_2">
                  <span class="span1">¥{{ item.xianjia }}</span>
                  <span class="span2">¥{{ item.yuanjia }}</span>
                </div>
                <div class="goodsmsg_3">
                  <div class="imgs"></div>
                  <span class="span3">{{ item.changjia }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <Bottom></Bottom> -->
  </div>
</template>

<script>
import Nav from "@/components/nav/nav.vue";
import Bottom from "@/components/bottom/bottom.vue";
export default {
  inject: ["reload"],
  data() {
    return {
      list: [
        {
          title: '医疗器械',
          id: 'ylqx',
          goodslist: [
            {
              src: require("../../assets/images/detail_1/21.png"),
              name: "欧姆龙-智能电子血压计手腕式(HEM-6111",
              yuanjia: "288.00",
              xianjia: "188.00",
              guige: "1个",
              changjia: "欧姆龙",
              // qiye: "",
              // pinpai: "",
              // xinghao: "",
              // zhuce: "",
              detail_1: require("../../assets/images/detail_1/21.png"),
              detail_2: require("../../assets/images/detail_2/21.png"),
              detail_3: require("../../assets/images/detail_3/21.png"),
              // youtu1: require("../../assets/images/3.png"),
              // youtu1_1: "噗噗管",
              // youtu1_2: "¥99.00",
              // youtu2: require("../../assets/images/3.png"),
              // youtu2_1: "胃健康管理服务",
              // youtu2_2: "¥4899.00",
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/22.png"),
              name: "欧姆龙智能电子血压计(上臂式)HEM-7200",
              yuanjia: "323.00",
              xianjia: "288.00",
              guige: "HEM-7200上臂式",
              changjia: "欧姆龙(大连)有限公司",
              detail_1: require("../../assets/images/detail_1/22.png"),
              detail_2: require("../../assets/images/detail_2/22.png"),
              detail_3: require("../../assets/images/detail_3/22.png"),
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/23.png"),
              name: "欧姆龙智能电子血压计HEM-7112(上臂式)",
              yuanjia: "298.00",
              xianjia: "220.00",
              guige: "1台",
              changjia: "欧姆龙(大连)有限公司",
              detail_1: require("../../assets/images/detail_1/23.png"),
              detail_2: require("../../assets/images/detail_2/23.png"),
              detail_3: require("../../assets/images/detail_3/23.png"),
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/24.png"),
              name: "欧姆龙电子血压计HEM-8102A(上臂式)",
              yuanjia: "232.00",
              xianjia: "199.00",
              guige: "1台",
              changjia: "欧姆龙(大连)有限公司",
              detail_1: require("../../assets/images/detail_1/24.png"),
              detail_2: require("../../assets/images/detail_2/24.png"),
              detail_3: require("../../assets/images/detail_3/24.png"),
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/25.png"),
              name: "电子血压计HEM-8611(手腕式)(欧姆龙)",
              yuanjia: "388.00",
              xianjia: "228.00",
              guige: "1台",
              changjia: "欧姆龙(大连)有限公司",
              detail_1: require("../../assets/images/detail_1/25.png"),
              detail_2: require("../../assets/images/detail_2/25.png"),
              detail_3: require("../../assets/images/detail_3/25.png"),
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/26.png"),
              name: "欧姆龙电子血压计HEM-7117 (上臂式)",
              yuanjia: "299.00",
              xianjia: "238.00",
              guige: "1台",
              changjia: "欧姆龙（大连）有限公司\n",
              detail_1: require("../../assets/images/detail_1/26.png"),
              detail_2: require("../../assets/images/detail_2/26.png"),
              detail_3: require("../../assets/images/detail_3/26.png"),
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/27.png"),
              name: "电子血压计(上臂式)(欧姆龙)",
              yuanjia: "250.00",
              xianjia: "198.00",
              guige: "1台",
              changjia: "欧姆龙(大连)有限公司",
              detail_1: require("../../assets/images/detail_1/27.png"),
              detail_2: require("../../assets/images/detail_2/27.png"),
              detail_3: require("../../assets/images/detail_3/27.png"),
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/28.png"),
              name: "电子血压计(上臂式)(欧姆龙)",
              yuanjia: "420.00",
              xianjia: "335.00",
              guige: "1台",
              changjia: "欧姆龙(大连)有限公司",
              detail_1: require("../../assets/images/detail_1/28.png"),
              detail_2: require("../../assets/images/detail_2/28.png"),
              detail_3: require("../../assets/images/detail_3/28.png"),
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/29.png"),
              name: "欧姆龙电子血压计HEM-7133",
              yuanjia: "420.00",
              xianjia: "368.00",
              guige: "1台",
              changjia: "欧姆龙(大连)有限公司",
              detail_1: require("../../assets/images/detail_1/29.png"),
              detail_2: require("../../assets/images/detail_2/29.png"),
              detail_3: require("../../assets/images/detail_3/29.png"),
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/30.png"),
              name: "电子血压计(欧姆龙)",
              yuanjia: "388.00",
              xianjia: "299.00",
              guige: "1台",
              changjia: "欧姆龙健康医疗株式会社",
              detail_1: require("../../assets/images/detail_1/30.png"),
              detail_2: require("../../assets/images/detail_2/30.png"),
              detail_3: require("../../assets/images/detail_3/30.png"),
              sale: 3366,
            },
          ]
        },
        {
          title: '皮肤科药',
          id: 'pfky',
          goodslist: [
            {
              src: require("../../assets/images/detail_1/1.png"),
              name: "复方醋酸氟轻松酊(三花大唐)",
              yuanjia: "85.00",
              xianjia: "41.80",
              guige: "50ml",
              changjia: "内蒙古大唐药业股份有限公司",
              // qiye: "",
              // pinpai: "",
              // xinghao: "",
              // zhuce: "",
              detail_1: require("../../assets/images/detail_1/1.png"),
              detail_2: require("../../assets/images/detail_2/1.png"),
              detail_3: require("../../assets/images/detail_3/1.png"),
              // youtu1: require("../../assets/images/3.png"),
              // youtu1_1: "噗噗管",
              // youtu1_2: "¥99.00",
              // youtu2: require("../../assets/images/3.png"),
              // youtu2_1: "胃健康管理服务",
              // youtu2_2: "¥4899.00",
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/2.png"),
              name: "复方曲安奈德乳膏(康纳乐)",
              yuanjia: "75.00",
              xianjia: "36.00",
              guige: "15k",
              changjia: "中美上海施贵宝制药有限公司",
              detail_1: require("../../assets/images/detail_1/2.png"),
              detail_2: require("../../assets/images/detail_2/2.png"),
              detail_3: require("../../assets/images/detail_3/2.png"),
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/3.png"),
              name: "复方醋酸地塞米松乳膏(999)(皮炎平)",
              yuanjia: "32.00",
              xianjia: "12,50",
              guige: "20g",
              changjia: "华润三九医药股份有限公司",
              detail_1: require("../../assets/images/detail_1/3.png"),
              detail_2: require("../../assets/images/detail_2/3.png"),
              detail_3: require("../../assets/images/detail_3/3.png"),
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/4.png"),
              name: "曲安奈德新霉素贴膏(恒健)",
              yuanjia: "10.00",
              xianjia: "3.50",
              guige: "4cm*6cm",
              changjia: "广东恒健制药有限公司",
              detail_1: require("../../assets/images/detail_1/4.png"),
              detail_2: require("../../assets/images/detail_2/4.png"),
              detail_3: require("../../assets/images/detail_3/4.png"),
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/5.png"),
              name: "曲安奈德益康唑乳膏(派瑞松)",
              yuanjia: "32.00",
              xianjia: "27.80",
              guige: "15g",
              changjia: "西安杨森制药有限公司",
              detail_1: require("../../assets/images/detail_1/5.png"),
              detail_2: require("../../assets/images/detail_2/5.png"),
              detail_3: require("../../assets/images/detail_3/5.png"),
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/6.png"),
              name: "硝酸咪康唑乳膏(达克宁)",
              yuanjia: "32.00",
              xianjia: "22.80",
              guige: "15g",
              changjia: "西安杨森制药有限公司",
              detail_1: require("../../assets/images/detail_1/6.png"),
              detail_2: require("../../assets/images/detail_2/6.png"),
              detail_3: require("../../assets/images/detail_3/6.png"),
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/7.png"),
              name: "润燥止痒胶囊(同济堂)",
              yuanjia: "31.00",
              xianjia: "28.50",
              guige: "礼盒装",
              changjia: "国药集团同济堂(贵州)制药有限公司",
              detail_1: require("../../assets/images/detail_1/7.png"),
              detail_2: require("../../assets/images/detail_2/7.png"),
              detail_3: require("../../assets/images/detail_3/7.png"),
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/8.png"),
              name: "荨麻疹丸(恒帝)",
              yuanjia: "12.00",
              xianjia: "7.90",
              guige: "10g*4袋",
              changjia: "吉林龙泰制药股份有限公司",
              detail_1: require("../../assets/images/detail_1/8.png"),
              detail_2: require("../../assets/images/detail_2/8.png"),
              detail_3: require("../../assets/images/detail_3/8.png"),
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/9.png"),
              name: "湿疡气雾剂(方圆牌)",
              yuanjia: "30.00",
              xianjia: "20.00",
              guige: "14g",
              changjia: "北京海德润医药集团有限公司",
              detail_1: require("../../assets/images/detail_1/9.png"),
              detail_2: require("../../assets/images/detail_2/9.png"),
              detail_3: require("../../assets/images/detail_3/9.png"),
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/10.png"),
              name: "卤米松乳膏(澳能)",
              yuanjia: "31.00",
              xianjia: "24.00",
              guige: "10g",
              changjia: "Bright Future Pharmaceuticals...",
              detail_1: require("../../assets/images/detail_1/10.png"),
              detail_2: require("../../assets/images/detail_2/10.png"),
              detail_3: require("../../assets/images/detail_3/10.png"),
              sale: 3366,
            },
          ]
        },
        {
          title: '儿童用药',
          id: 'etyy',
          goodslist: [
            {
              src: require("../../assets/images/detail_1/11.png"),
              name: "儿科七厘散(冯了性)",
              yuanjia: "12.00",
              xianjia: "9.90",
              guige: "0.26g*10袋",
              changjia: "国药集团冯了性(佛山)药业有限公司",
              // qiye: "",
              // pinpai: "",
              // xinghao: "",
              // zhuce: "",
              detail_1: require("../../assets/images/detail_1/11.png"),
              detail_2: require("../../assets/images/detail_2/11.png"),
              detail_3: require("../../assets/images/detail_3/11.png"),
              // youtu1: require("../../assets/images/3.png"),
              // youtu1_1: "噗噗管",
              // youtu1_2: "¥99.00",
              // youtu2: require("../../assets/images/3.png"),
              // youtu2_1: "胃健康管理服务",
              // youtu2_2: "¥4899.00",
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/12.png"),
              name: "儿童复方氨酚肾素片(科达琳)",
              yuanjia: "32.00",
              xianjia: "25.80",
              guige: "12片",
              changjia: "幸福医药有限公司",
              detail_1: require("../../assets/images/detail_1/12.png"),
              detail_2: require("../../assets/images/detail_2/12.png"),
              detail_3: require("../../assets/images/detail_3/12.png"),
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/13.png"),
              name: "天黄猴枣散(敬修堂)",
              yuanjia: "52.00",
              xianjia: "48.00",
              guige: "0.15g*10瓶",
              changjia: "广州白云山敬修堂药业股份有限公司",
              detail_1: require("../../assets/images/detail_1/13.png"),
              detail_2: require("../../assets/images/detail_2/13.png"),
              detail_3: require("../../assets/images/detail_3/13.png"),
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/14.png"),
              name: "小柴胡颗粒(白云山)",
              yuanjia: "25.00",
              xianjia: "22.00",
              guige: "10g*10袋",
              changjia: "广州白云山光华制药股份有限公司",
              detail_1: require("../../assets/images/detail_1/14.png"),
              detail_2: require("../../assets/images/detail_2/14.png"),
              detail_3: require("../../assets/images/detail_3/14.png"),
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/15.png"),
              name: "小儿氨酚黄那敏颗粒(999)",
              yuanjia: "22。00",
              xianjia: "14.80",
              guige: "6g*10袋",
              changjia: "华润三九(北京)药业有限公司",
              detail_1: require("../../assets/images/detail_1/15.png"),
              detail_2: require("../../assets/images/detail_2/15.png"),
              detail_3: require("../../assets/images/detail_3/15.png"),
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/16.png"),
              name: "小儿氨酚黄那敏颗粒(小快克)",
              yuanjia: "32.00",
              xianjia: "22.90",
              guige: "10袋",
              changjia: "浙江亚峰药厂有限公司",
              detail_1: require("../../assets/images/detail_1/16.png"),
              detail_2: require("../../assets/images/detail_2/16.png"),
              detail_3: require("../../assets/images/detail_3/16.png"),
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/17.png"),
              name: "小儿七星茶颗粒(三公仔)",
              yuanjia: "22.00",
              xianjia: "14.90",
              guige: "7g*10包",
              changjia: "广州王老吉药业股份有限公司\n",
              detail_1: require("../../assets/images/detail_1/17.png"),
              detail_2: require("../../assets/images/detail_2/17.png"),
              detail_3: require("../../assets/images/detail_3/17.png"),
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/18.png"),
              name: "布洛芬混悬液(美林)",
              yuanjia: "42.00",
              xianjia: "37.90",
              guige: "100ml",
              changjia: "上海强生制药有限公司",
              detail_1: require("../../assets/images/detail_1/18.png"),
              detail_2: require("../../assets/images/detail_2/18.png"),
              detail_3: require("../../assets/images/detail_3/18.png"),
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/19.png"),
              name: "小儿氨酚黄那敏颗粒(雪丹)",
              yuanjia: "10.00",
              xianjia: "6.00",
              guige: "10袋",
              changjia: "贵州科辉制药有限责任公司",
              detail_1: require("../../assets/images/detail_1/19.png"),
              detail_2: require("../../assets/images/detail_2/19.png"),
              detail_3: require("../../assets/images/detail_3/19.png"),
              sale: 3366,
            },
            {
              src: require("../../assets/images/detail_1/20.png"),
              name: "小儿氨酚烷胺颗粒(优卡丹)",
              yuanjia: "12.00",
              xianjia: "8.80",
              guige: "6g*10袋",
              changjia: "江西铜鼓仁和制药有限公司",
              detail_1: require("../../assets/images/detail_1/20.png"),
              detail_2: require("../../assets/images/detail_2/20.png"),
              detail_3: require("../../assets/images/detail_3/20.png"),
              sale: 3366,
            },
          ]
        },

      ],
      goodslist: [
        {
          src: require("../../assets/images/yao2.png"),
          name: "幽幽管",
          yuanjia: "169",
          xianjia: "149",
          guige: "1人/份",
          changjia: "九和堂药房",
          qiye: "杭州诺辉健康科技有限公司",
          pinpai: "诺辉健康",
          xinghao: "国械注准20213401126",
          zhuce: "国械注准20213401126",
          detail_1: require("../../assets/images/detail1-1.png"),
          detail_2: require("../../assets/images/detail1-2.png"),
          detail_3: require("../../assets/images/detail1-3.png"),
          youtu1: require("../../assets/images/youtu1.png"),
          youtu1_1: "噗噗管",
          youtu1_2: "¥99.00",
          youtu2: require("../../assets/images/youtu2.png"),
          youtu2_1: "胃健康管理服务",
          youtu2_2: "¥199.00",
          sale: 100,
        },
        {
          src: require("../../assets/images/yao1.png"),
          name: "噗噗管",
          yuanjia: "119",
          xianjia: "99",
          guige: "1人/份",
          qiye: "杭州诺辉健康科技有限公司",
          changjia: "诺辉健康旗舰店",
          pinpai: "诺辉健康",
          xinghao: "浙械注准20182400230",
          zhuce: "浙械注准20182400230",
          detail_1: require("../../assets/images/detail02-1.png"),
          detail_2: require("../../assets/images/detail02-2.png"),
          detail_3: require("../../assets/images/detail2-3.png"),
          youtu1: require("../../assets/images/putu1.png"),
          youtu1_1: "幽幽管",
          youtu1_2: "¥149.00",
          youtu2: require("../../assets/images/youtu2.png"),
          youtu2_1: "胃健康管理服务",
          youtu2_2: "¥199.00",
          sale: 200,
        },
        {
          src: require("../../assets/images/wei11.png"),
          name: "胃健康管理服务",
          yuanjia: "269",
          xianjia: "199",
          changjia: "平安健康",
          qiye: " - ",
          guige: "1人/份",
          pinpai: "胃健康管理服务",
          xinghao: " - ",
          zhuce: " - ",
          detail_1: require("../../assets/images/detail4-1.png"),
          detail_2: require("../../assets/images/detail4-2.png"),
          detail_3: require("../../assets/images/detail4-3.png"),
          youtu1: require("../../assets/images/putu1.png"),
          youtu1_1: "幽幽管",
          youtu1_2: "¥149.00",
          youtu2: require("../../assets/images/youtu1.png"),
          youtu2_1: "噗噗管",
          youtu2_2: "¥99.00",
          sale: 320,
        },
      ],
      active: 10,

      showdis: true,
      menuitem: "",
      menu: true,
      index: "",
    };
  },
  components: {
    Nav,
    Bottom,
  },
  mounted() {
    var dislist = document.querySelectorAll(".diease");
    dislist.forEach((item) => {
      item.style.display = "none";
    });
  },
  methods: {
    toDetails(index, index1) {
      this.index = index;
      this.$router.push({
        name: "details",
        query: { d: JSON.stringify(this.list[index1].goodslist[index]) },
      });
    },
    toSearch() {
      this.$router.push({
        name: "search",
        params: { details: this.goodslist },
      });
    },
    choose(index) {
      this.active = index;
    },
    bannerleave() {
      var dislist = document.querySelectorAll(".diease");
      dislist.forEach((item) => {
        item.style.display = "none";
        this.active = 10;
      });
    },
    refresh() {
      location.reload();
    },
  },
};
</script>
<style scoped lang='less' src="./index.less"></style>
